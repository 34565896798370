import {Modal} from "react-bootstrap";
import {Field, setIn} from "formik";
import {useIntl} from "react-intl";
import {AdminService, WLOCreditAPIService} from "../../../../services";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useLoginContext} from "../../../../hooks/useLoginContext";
import { useFormik } from 'formik';
import {useRequestContext} from "../../../../hooks/useRequestContext";
interface ModalReferences {
    show: boolean;
    onHide: () => void;
    errors?: any;
    values?: any;
    valorrequest?:boolean;
    codeudor?:boolean;
    refresh?: () => void;
}

export function ModalReferences(props: ModalReferences) {
    const {values, onHide,valorrequest,codeudor, refresh} = props;
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [one] = useState(false)
    const [listReferencias,setListReferen] = useState([])
    
   useEffect(()=>{
    getAll()
   },[one])
   const getAll= async ()=>{
    const refer= await AdminService.getReferencias();
    if(refer.payload.data.ListRef.Datos_Referencias.length>0){
        setListReferen(refer.payload.data.ListRef.Datos_Referencias)
    }
   }
    const handleSaveReference = (e: any) => {        
        (async () => { 
            e.preventDefault();
            if(valorrequest){
                if(codeudor){
                    document.getElementById("boton_referencias1")?.click()
                    console.log(document.getElementById("boton_referencias1")?.addEventListener)
                }else{
                    document.getElementById("boton_referencias")?.click()
                }
            }else{
                if(values.s_ref_telcel){
                    const references: any = await WLOCreditAPIService.addReferences({
                        s_num_solicitud: request.numSol,
                        s_identificacion: request.numIdentification!,
                        s_tipo_identificacion: request.typeIdentification!,
                        s_ref_tipo: values.s_ref_tipo!,
                        s_ref_parentes: values.s_ref_parentes!,
                        s_ref_nomcomple: values.s_ref_nomcomple!,
                        s_ref_telfij: values.s_ref_telfij!,
                        s_ref_telcel: values.s_ref_telcel!
                    })
                    if (references.payload.result > 0) {
                        const consultaReferences: any = await WLOCreditAPIService.addReferences({
                            s_num_solicitud: request.numSol,
                            s_identificacion: request.numIdentification!,
                            s_tipo_identificacion: request.typeIdentification!,
                            })
                        
                        if (consultaReferences.payload.result > 0) {
                            values.s_ref_tipo=""
                            values.s_ref_parentes=""
                            values.s_ref_nomcomple=""
                            values.s_ref_telfij=""
                            values.s_ref_telcel=""
                            let referencesFilter: any = consultaReferences.payload.data.datos_referencia.datos_refer.filter((item: { i_pws_num_solicitud: string | undefined; }) => item.i_pws_num_solicitud === request.numSol);
                            setRequest({...request, references: referencesFilter});
                            toast.success('Se agregado la referencia de manera exitosa');
                            if (refresh != undefined){
                                refresh();
                            }
                        }    
                    }else{
                        toast.error('No se ha podido guardar la referencia');
                    }
                }else{
                    toast.error('Debes colocar al menos el teléfono movil 1');

                }
                
            }
           
        })();
        onHide();
    }

    const intl = useIntl();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="title-modal-spouse">
                        {'Información de la referencia'}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "relationship"})}</div>
                            <Field as="select"
                                   className={'form-know-you-item-input-medium'}
                                   name="s_ref_parentes">
                                <option>{intl.formatMessage({id: "relationship"})}</option>
                                {listReferencias.map((valor:any)=>{
                                    return(<>
                                <option value={valor.s_codigo}>{valor.s_descripcion_legal}</option>
                                    </>)
                                })}
                            </Field>
                        </div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{"Tipo de referencia"}</div>
                            <Field as="select"
                                   className={'form-know-you-item-input-medium'}
                                   name="s_ref_tipo">
                                <option>{'Tipo de referencia'}</option>
                                <option value={'1'}>{'FAMILIAR'}</option>
                                <option value={'2'}>{'PERSONAL'}</option>
                            </Field>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{'Nombre completo'}</div>
                            <Field id={"s_ref_nomcomple"}
                                   name={"s_ref_nomcomple"}
                                   className={'form-know-you-item-input-xs display-flex'}
                                   placeholder={'Nombre completo'}/></div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_phone_1"})}</div>
                            <Field id={"s_ref_telfij"}
                                   name={"s_ref_telfij"}
                                   className={'form-know-you-item-input-xs display-flex'}
                                   placeholder={intl.formatMessage({id: "placeholder_phone_1"})}/></div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mobile_phone_1"})}</div>
                            <Field id={"s_ref_telcel"}
                                   name={"s_ref_telcel"}
                                   className={'form-know-you-item-input-xs display-flex'}
                                   placeholder={intl.formatMessage({id: "placeholder_mobile_phone_1"})}/>
                            <br/>
                        </div>
                        <div className="col"/>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={"form-know-you-item-button"} onClick={props.onHide}>
                                {intl.formatMessage({id: "button_cancel"})}
                            </div>
                        </div>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className={"form-know-you-item-button-gray"} onClick={handleSaveReference}>
                                {intl.formatMessage({id: "button_continue"})}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export function ModalReferences2(props: ModalReferences) {
    const {values, onHide,valorrequest,codeudor,  refresh} = props;
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [one] = useState(false)
    const [listReferencias,setListReferen] = useState([])

    const [input,setInpunt] = useState({
        s_ref_tipo: values?.i_ref_tip,
        s_ref_parentes: values?.i_pws_vinculo,
        s_ref_nomcomple: values?.c_pws_ref_nomcomple,
        s_ref_telfij: values?.i_pws_ref_telfu ,
        s_ref_telcel: values?.i_pws_ref_telcle,
    })


    const haddlerChange = (e:any) => {
        let {value, name} = e.target;
        setInpunt({
            ...input,
            [name]:value,
        })
    };

    useEffect(()=>{
    getAll()
    },[one])
    const getAll= async ()=>{
    const refer= await AdminService.getReferencias();
    if(refer.payload.data.ListRef.Datos_Referencias.length>0){
        setListReferen(refer.payload.data.ListRef.Datos_Referencias)     
    }
    }
    const handleUpdateReference = async (e: any) => {
            e.preventDefault();

        try{
            const references = await WLOCreditAPIService.updateReferences({
                s_consecutivo: values.n_consecutivo,
                s_num_solicitud: values.i_pws_num_solicitud,
                s_identificacion: values.c_pws_identificacion,
                s_tipo_identificacion: values.i_pws_tip_identificacon,
                s_ref_tipo: input.s_ref_tipo,
                s_ref_parentes: input.s_ref_parentes,
                s_ref_nomcomple: input.s_ref_nomcomple,
                s_ref_telfij: input.s_ref_telfij,
                s_ref_telcel: input.s_ref_telcel
            })
            if (references.payload.result === "1") {
                const consultaReferences: any = await WLOCreditAPIService.addReferences({
                    s_num_solicitud: values.i_pws_num_solicitud,
                    s_identificacion: values.c_pws_identificacion!,
                    s_tipo_identificacion: values.i_pws_tip_identificacon!,
                    })
                    if (consultaReferences.payload.result > 0) {
                        values.s_ref_tipo=""
                        values.s_ref_parentes=""
                        values.s_ref_nomcomple=""
                        values.s_ref_telfij=""
                        values.s_ref_telcel=""
                        let referencesFilter: any = consultaReferences.payload.data.datos_referencia.datos_refer.filter((item: { i_pws_num_solicitud: string | undefined; }) => item.i_pws_num_solicitud === request.numSol);
                        setRequest({...request, references: referencesFilter});
                        toast.success('Se actualizado la referencia de manera exitosa');
                       
                        if (refresh != undefined){
                            refresh();   
                        }
                    }  
            }
        }catch(error){
            console.log("mensaje de error",error);
        }
        onHide();
    }
    const intl = useIntl();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="title-modal-spouse">
                        {'Información de la referencia'}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "relationship"})}</div>
                            <Field as="select"
                                className={'form-know-you-item-input-medium'}
                                name="s_ref_parentes"
                                value={input.s_ref_parentes}
                                onChange={haddlerChange}
                                >
                                <option>{intl.formatMessage({id: "relationship"})}</option>
                                {listReferencias.map((valor:any)=>{
                                    return(<>
                                <option value={valor.s_codigo}>{valor.s_descripcion_legal}</option>
                                    </>)
                                })}
                            </Field>
                        </div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{"Tipo de referencia"}</div>
                            <Field as="select"
                                className={'form-know-you-item-input-medium'}
                                name="s_ref_tipo"
                                value={input.s_ref_tipo}
                                onChange={haddlerChange}
                                >
                                <option>{'Tipo de referencia'}</option>
                                <option value={'1'}>{'FAMILIAR'}</option>
                                <option value={'2'}>{'PERSONAL'}</option>
                                
                            </Field>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{'Nombre completo'}</div>
                            <Field id={"s_ref_nomcomple"}
                                name={"s_ref_nomcomple"}
                                className={'form-know-you-item-input-xs display-flex'}
                                value={input.s_ref_nomcomple}
                                onChange={haddlerChange}
                                placeholder={' Nombre completo'}
                                />
                        </div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_phone_1"})}</div>
                            <Field id={"s_ref_telfij"}
                                name={"s_ref_telfij"}
                                className={'form-know-you-item-input-xs display-flex'}
                                value={input.s_ref_telfij}
                                onChange={haddlerChange}
                                placeholder={intl.formatMessage({ id: "placeholder_phone_1" })}
                            />
                        </div>
                        <div className="col">
                        <div className={"label-fields-peps-mobile display-flex"}>
                            {intl.formatMessage({ id: "placeholder_mobile_phone_1" })}
                        </div>
                        <Field
                            id={"s_ref_telcel"}
                            name={"s_ref_telcel"}
                            className={'form-know-you-item-input-xs display-flex'}
                            placeholder={intl.formatMessage({ id: "placeholder_mobile_phone_1" })}
                            value={input.s_ref_telcel}
                            onChange={haddlerChange}
                        />
                        <br/>
                    </div>
                        <div className="col"/>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={"form-know-you-item-button"} onClick={props.onHide}>
                                {intl.formatMessage({id: "button_cancel"})}
                            </div>
                        </div>
                        <div className="col">
                        </div>
                        <div className="col">
                            <div className={"form-know-you-item-button-gray"} onClick={handleUpdateReference}>
                                {"Actualizar"}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}