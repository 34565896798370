import React, {useEffect} from 'react';
import './com.scss';
import { Color } from '../../ColorPalate';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';

interface ButtonProp {
    text: string;
    bg: string;
    color: string;
    px: number;
    py: number;
    mx: number;
    my: number;
}

export const Button : React.FC <ButtonProp> = ({text, bg, color, px, py, mx, my}) => {
    return(
        <button style={{background: bg, color: color, padding: `${py}px ${px}px`, margin: `${my}px ${mx}px`}}>{text}</button>
    )
}

interface TitleProp {
    text: string;
    color: string;
}

export const Title : React.FC <TitleProp> = ({text, color}) => {
    return(
        <p className='redhead' style={{color: color}} >{text}</p>
    )
}

interface FooterProp {
    text: string;
    bg: string;
    color: string;
}

export const Footer : React.FC <FooterProp> = ({text, bg, color}) => {
    return (
        <div className="footer" style={{backgroundColor: bg, color: color}}>
            <p>{text}</p>
        </div>
    )
}

export const Components = () => {
    useEffect(() => {
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
      }, []);
  return (
    <>
    <ResponsiveAppBar />
    <div className="components">
        <div className="color-palate">
            <div className="primary" style={{backgroundColor: `${Color.primaryColor}`}}>Primary Color</div>
            <div className="secondary" style={{backgroundColor: `${Color.secondaryColor}`}}>Secondary Color</div>
            <div className="tartiary" style={{backgroundColor: `${Color.tartiaryColor}`}}>Tartiary Color</div>
            <div className="fourth" style={{backgroundColor: `${Color.fourthColor}`}}>Four Color</div>
        </div>
        <div className="bg-color-palate">
            <div className="bgprimary" style={{backgroundColor: `${Color.bgPrimaryColor}`, color: Color.textColorBlack}}>Primary BG color</div>
            <div className="bgsecondary" style={{backgroundColor: `${Color.bgSecondaryColor}`}}>Secondary BG color</div>
            <div className="bgtartiary" style={{backgroundColor: `${Color.bgTartiaryColor}`}}>Tartiary BG color</div>
            <div className="bgfourth" style={{backgroundColor: `${Color.bgFourthColor}`, color: Color.textColorBlack}}>Four BG color</div>
        </div>
        <Title text={`Title`} color={Color.primaryColor} />
        <Button text={`Flexible Button`} bg={`${Color.primaryColor}`} color={`${Color.textColorWhite}`} px={45} py={10} mx={10} my={10} />
        <Button text={`Flexible Button`} bg={`${Color.secondaryColor}`} color={`${Color.textColorWhite}`} px={45} py={10} mx={10} my={10} />
        <Button text={`Flexible Button`} bg={`${Color.tartiaryColor}`} color={`${Color.textColorWhite}`} px={45} py={10} mx={10} my={10} />
        <Footer text={`All Rights Reseverd By @WLO`} bg={Color.bgSecondaryColor} color={Color.textColorWhite} />
    </div>
    </>
  )
};