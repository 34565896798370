import './addEdit.scss';
import { Field, Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InventoryService } from '../../../../services';
import { Edit } from '../../../../assets/icons/component';
import { Input, Select } from '../../../shared/InputSelet';
import _ from 'lodash';

interface ItemProp {
    item: {
        R_s_cod_atr: string,
        R_s_nom_atr: string,
    };
}

export const EditProduct = (productInfo: any) => {

    const { state } = useLocation();
    const [option1, setoption1] = React.useState(false);
    const [Stock, setStock] = React.useState([] as any);
    const [atributtes, setAtributtes] = React.useState([] as any);
    const [infoCategory, setInfoCategory] = React.useState([] as any);
    const [PruebaVector, setPruebaVector] = React.useState([] as any);
    const [infoProduct, setinfoProduct] = React.useState([] as any);
    const [infoAtriProduct, setInfoAtriProduct] = React.useState([] as any);
    const [uploadedImageUrl, setUploadedImageUrl] = React.useState<string | null>(null);


    useEffect(() => {
        setinfoProduct(productInfo.productInfo);
        allAttribute();
        setInfoCategory(state);
        if (productInfo.productInfo.RespAtr && productInfo.productInfo.RespAtr.RespuestaAtr) {
            setInfoAtriProduct(productInfo.productInfo.RespAtr.RespuestaAtr);
        }
    }, []);

    const handleSubmit = async (values: any) => {
        var atr_tip_product = "";
        for (var i = 0; i < PruebaVector.length; i++) {
            if (PruebaVector[i][0] !== null && PruebaVector[i][2] !== null)
                atr_tip_product = atr_tip_product + PruebaVector[i][0] + "," + PruebaVector[i][2] + ";";
        }

        if (productInfo.productInfo.R_s_cod_prod != null) {
            const updateProduct = await InventoryService.postProductUpdate({
                pws_cod_prod: productInfo.productInfo.R_s_cod_prod,
                pws_c_descrip: values.pws_c_descrip,
                pws_precio_uni: values.pws_precio_uni,
                pws_precio_comer: values.pws_precio_comer,
                pws_stock: values.pws_stock,
                pws_imagen: uploadedImageUrl || productInfo.productInfo.R_s_link_img,
                pws_cod_cate: infoCategory.R_s_cod_cate,
                pws_tipo_prod: "1",
                pws_atr_tip_productos: atr_tip_product
            });
            if (updateProduct.payload.data.R_s_cod == "1") {
                toast.success(updateProduct.payload.message)
            }

        } else {
            toast.success("No se encontraron datos, vuelva a intentarlo")
        }

    }

    const allAttribute = async () => {
        const valorProduct = await InventoryService.postAttributesQuery({
            pws_cod_atr: "",
        });
        if (valorProduct.payload.data.RespDetAtr != null) {
            const ResponseService = valorProduct.payload.data.RespDetAtr.RespuestaDetAtri;
            const miMatriz = new Array(ResponseService.length).fill(null).map(() => new Array(3).fill(null));
            for (var i = 0; i < ResponseService.length; i++) {
                miMatriz[i][0] = ResponseService[i].R_s_cod_atr;
                miMatriz[i][1] = ResponseService[i].R_s_nom_atr;
            }
            setPruebaVector(miMatriz)
            setAtributtes(ResponseService)
        }
    }

    function handleFileInputChange(event:any) {
        const file = event.target.files[0];        
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            fetch( process.env.REACT_APP_BACK! , {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    setUploadedImageUrl(data.url);   
                })
                .catch(error => {
                console.error('Error al subir la imagen:', error);
                });
        }
    }


    const Item: React.FC<ItemProp> = ({item}) => {
        const handleFileInputChangePrueba = (e: any) => {
            for (var i = 0; i < PruebaVector.length; i++) {
                const Num = i + 1;
                if (item.R_s_cod_atr === Num.toString()) {
                    PruebaVector[i][2] = e.target.value;
                }
            }
        }

        return (
            <ul>
                <li className={'Numeracion'}>{item.R_s_cod_atr}</li>
                <li className={'TipoAtributo'}>{item.R_s_nom_atr}</li>
                <input className={'Descripcion'} type={'text'}
                    value={_.filter(infoAtriProduct,
                        { R_s_cod_atr: item.R_s_cod_atr, R_s_nom_atr: item.R_s_nom_atr }).length > 0 ?
                        _.filter(infoAtriProduct,
                            { R_s_cod_atr: item.R_s_cod_atr, R_s_nom_atr: item.R_s_nom_atr })[0].R_s_valor_atr :""}
                    onChange={handleFileInputChangePrueba} />
            </ul>
        );
    }

    return (
        <div className="editInfo">
            <Formik
                initialValues={{
                    pws_c_descrip: productInfo.productInfo.R_s_nom_prod,
                    pws_precio_uni: productInfo.productInfo.R_s_precio_uni,
                    pws_precio_comer: productInfo.productInfo.R_s_precio_comer,
                    pws_stock: productInfo.productInfo.R_s_unid_stock,
                    pws_imagen: productInfo.productInfo.R_s_link_img,
                }}
                onSubmit={handleSubmit}>

                {({ errors, isSubmitting }) => {
                    return (
                        <>
                            <Form>
                                <div className="image">
                                    <img src={productInfo.productInfo.R_s_link_img} alt="" width="200" height="200" />
                                    <div className="subtitle">Foto del producto</div>
                                    <div className={option1 ? "document check" : "document"} onClick={(eve) => { eve.currentTarget.querySelector('input')?.click() }}>
                                        <input data-id="1" type="file" name="file-input" id="file-input" className="file-input__input" accept="application/pdf, image/png, image/jpg, image/jpeg" onChange={handleFileInputChange} />
                                        <button type='button'>Cambiar foto</button>
                                    </div>
                                </div>
                                <div className="info">
                                    <ul>
                                        <li className="LiStyle">
                                            <label>Nombre del producto</label>
                                            <Field
                                                type={'text'}
                                                className="InDataEntrada"
                                                name='pws_c_descrip' >
                                            </Field>
                                        </li>
                                        <li className="LiStyle">
                                            <label>Stock</label>
                                            <Field
                                                type={'text'}
                                                className="InDataEntrada"
                                                name='pws_stock' >
                                            </Field>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="LiStyle">
                                            <label>Valor comercial de la moto</label>
                                            <Field
                                                type={'text'}
                                                className="InDataEntrada"
                                                name='pws_precio_comer' >
                                            </Field>
                                        </li>
                                        <li className="LiStyle">
                                            <label>Valor unitario de la moto</label>
                                            <Field
                                                type={'text'}
                                                className="InDataEntrada"
                                                name='pws_precio_uni' >
                                            </Field>

                                        </li>
                                    </ul>
                                    <div className="attributos">
                                        <p>Atributos</p>
                                        <div className="table">
                                            <div className="table-body">
                                                <div className="body">
                                                    {
                                                        atributtes && atributtes.map((item: any, i: any) => {                                  
                                                                return (
                                                                    <Item item={item} key={i}/>
                                                                )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="submit">
                                            <button style={{ background: '#000000', color: '#fff', padding: '5px 10px', borderRadius: '9px' }} >Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )
                }
                }
                
            </Formik>
        </div>
    )
}
