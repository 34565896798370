import React, {useEffect, useRef, useState} from 'react';
import './sd.scss';
import {ArrowBack, Padding} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {Search, ArrowDown} from '../../../assets/icons/component';
import {SolicTable} from './SolicTable.js';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from "../../../Router";
import { WLOCreditAPIService } from '../../../services';
import {WloCreditoService} from '../../../types';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import ClockLoader from 'react-spinners/ClockLoader';

const Options = [
    {
        option: 'Cliente'
    },
        {
            option: 'Ciudad'
    },
    {
        option: 'Estado Firma'
    },
    {
        option: 'Estado Credito'
    },
    {
        option: 'Linea de Credito'
    },
    {
        option: 'Oficina'
    },
    {
        option: 'Documento'
    },
    {
        option: 'Número de Solicitud'
    }
]

interface OptionProps {
    Options: {
        option: string
    }[];
    bg: string;
    clr: string;
    str: string;
}

export const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <li>
            <ArrowDown stroke={str} />
            <select name="search" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </li>
    )
}
export const Select2: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <>
            <ArrowDown stroke={str} />
            <select name="search" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </>
    )
}

export const SolicDocument: React.FC = () => {
    const navigate = useNavigate();
    const textInputRef = useRef<HTMLInputElement>(null);
    const [one]= useState(false)
    const [table,setTable]= useState([])
    const [table2,setTable2]= useState([])
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    let [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (event: React.FormEvent) => {
        const select = document.getElementById('search') as HTMLSelectElement | null;
        event.preventDefault();
        let enteredText = textInputRef.current!.value;
        if(table.length>0){
            if(select?.value==="Cliente"){
               const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Nombres.toUpperCase()).includes(enteredText.toUpperCase())){
                       return true;
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Ciudad"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_ciudad.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Estado Firma"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Estado_Firma.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Estado Credito"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Estado_credito.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Linea de Credito"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Lincre.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Oficina"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_nom_oficina.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Documento"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_Identificacion.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }else if(select?.value==="Número de Solicitud"){
                const tablaIngr= table.filter((valor:any)=>{
                    if((valor.Pws_num_solicitud.toUpperCase()).includes(enteredText.toUpperCase())){
                        return true
                    }else{
                        return false
                    }
                })
                setTable2(tablaIngr)
            }
        }
    }
    const applyDateFilter = () => {
        setIsLoading(true);
        const formatDate = (date:any) => {
          if (!date) return '';
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          return `${month}/${day}/${year}`;
        };
      
        const inicio = formatDate(startDate);
        const fin = formatDate(endDate);
      
        let valoragregado = {
          Pws_num_solicitud: "",
          Pws_fec_solicitud_ini: inicio || formatDate(new Date(new Date().setDate(new Date().getDate() - 30))),
          Pws_fec_solicitud_fin: fin || formatDate(new Date()),
          Pws_Tip_estado: "",
          Pws_Tip_Consulta: "1"
        };
      
        WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado).then((consulta) => {
          if (consulta.payload.data.datos_soli) {
            if (consulta.payload.data.datos_soli.datos_Sol.length > 0) {
              let solicitudesVendedor = consulta.payload.data.datos_soli.datos_Sol.filter((valor:any) => {
                let dataAseesor = localStorage.getItem('User') || '{}';
                let jsonDataAsesor = JSON.parse(dataAseesor);
                let docAsesor = jsonDataAsesor.idUser;
                let tipoPerfil = jsonDataAsesor.idProfile;
                if (tipoPerfil == "4") {
                  if (valor.Pws_iden_vende) {
                    if (valor.Pws_iden_vende == docAsesor) {
                      return true;
                    }
                    return false;
                  }
                  return false;
                }
                return true;
              });
      
              setTable(solicitudesVendedor);
              setTable2(solicitudesVendedor);
            }
          }
          setIsLoading(false);
        }, (error) => {
          console.log(error);
        });
      };

    useEffect(()=>{
        functionss()
    },[one])
    const functionss = ()=> {
        setIsLoading(true);
        const today = new Date();
        const date30DaysBefore = new Date();
        date30DaysBefore.setDate(today.getDate() - 30);
        const formatDate = (date:any) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0-11
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
          };
        const fin = formatDate(today);
        const inicio = formatDate(date30DaysBefore); 
        
        let valoragregado:WloCreditoService.SolicitudListadoRequest={
        Pws_num_solicitud:"",
        Pws_fec_solicitud_ini:inicio,
        Pws_fec_solicitud_fin:fin,
        Pws_Tip_estado: "",
        Pws_Tip_Consulta: "1"
        }
         WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado).then((consulta)=>{
       if(consulta.payload.data.datos_soli){
        if(consulta.payload.data.datos_soli.datos_Sol.length>0){

            let solicitudesVendedor = consulta.payload.data.datos_soli.datos_Sol.filter((valor:any)=>{
                    let dataAseesor = localStorage.getItem('User') || '{}';
                    let jsonDataAsesor = JSON.parse(dataAseesor);
                    let docAsesor = jsonDataAsesor.idUser;
                    let tipoPerfil = jsonDataAsesor.idProfile;
                   if(tipoPerfil == "4"){
                        if(valor.Pws_iden_vende){
                            if(valor.Pws_iden_vende == docAsesor){
                                return true;
                            }
                            return false;
                        }
                        return false;
                   }
                   return true;
            })

            setTable(solicitudesVendedor)
            setTable2(solicitudesVendedor)
            setIsLoading(false);
        }
       }
         },(error)=>{
            console.log(error)
         })
      }
      
  return (
      <div className="solicDoc">
        <ClockLoader id='spinner' color={"#d72222"} loading={isLoading} size={100}/>
        <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={()=>{
                            navigate(AppRoutes.MENU_USER, {replace: true});
                        }} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {'Atrás'}
                    </Fab>
        <div className="solicDocument">
            <h4>Solicitudes de crédito</h4>
            <div className="solicTable">
                <div className="search">
                    <form action="" method="post" onSubmit={handleSubmit}>
                            <Select Options={Options} bg={`#4A4A4A`} clr={`#fff`} str={`#fff`} />
                        <div className="date-filter">
                            <div>
                                <h5>Filtrar por rango de fechas</h5>
                            </div>
                            <div className='rango-fechas'>
                                <DatePicker
                                    selectsStart
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    startDate={startDate || undefined}
                                    endDate={endDate || undefined}
                                    placeholderText="Fecha desde"
                                />
                                <DatePicker
                                     selectsEnd
                                     selected={endDate}
                                     onChange={(date) => setEndDate(date)}
                                     startDate={startDate || undefined}
                                     endDate={endDate || undefined}
                                     minDate={startDate || undefined}
                                     placeholderText="Fecha hasta"
                                />
                            </div>
                                <button onClick={applyDateFilter}>Aplicar filtro</button>
                        </div>
                        <li style={{marginTop:"4vh"}}>
                            <div onClick={handleSubmit}>
                            <Search  />
                            </div>
                            <input type="text" name="seacrh" placeholder="search" ref={textInputRef}/>
                        </li>
                    </form>
                </div>
                <div className="table">
                    <SolicTable value={{tablasva:table2,functionss}} />
                </div>
            </div>
        </div>
    </div>
  )
}
