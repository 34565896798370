import React from 'react';
import './palate.scss';
import { Formik } from 'formik';
import {Color} from '../../../ColorPalate';
import { AdminService } from '../../../services';

interface PalateProp {
  palate: {
    bg: string,
    br: string,
    name: string,
  }[];
  setFunc: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Palate : React.FC <PalateProp> = ({palate, setFunc}) => {
  return (
    <div className='palate'>
      <p className="palatehead">Ingresar el código <span>HEX</span> del nuevo color a implamentar</p>
      <Formik
        initialValues={{ primary: palate[1].bg, secondary: palate[0].bg, tartiary: palate[2].bg, fourth: palate[3].bg}}
        onSubmit={(values, { setSubmitting }) => {
          AdminService.
          addColors(values).
          then((agregado:any)=>{
            palate[0].bg = values.secondary;
            palate[1].bg = values.primary;
            palate[2].bg = values.tartiary;
            palate[3].bg = values.fourth;
            document.documentElement.style.setProperty('--primary-color', palate[1]?.bg);
            document.documentElement.style.setProperty('--secondary-color', palate[0]?.bg);
            document.documentElement.style.setProperty('--tartiary-color', palate[2]?.bg);
            document.documentElement.style.setProperty('--fourth-color', palate[3]?.bg);
            Color.primaryColor = palate[1]?.bg;
            console.log(agregado)
            setSubmitting(false);
            setFunc(false);
          })
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="body">
            <div className="palatebox">
              <div className="box" style={{background: palate[0].bg, border: palate[0].br}}></div>
              <input type="text" name={palate[0].name} placeholder={palate[0].bg} onChange={handleChange} onBlur={handleBlur} value={values.secondary} />
            </div>
            <div className="palatebox">
              <div className="box" style={{background: palate[1].bg, border: palate[1].br}}></div>
              <input type="text" name={palate[1].name} placeholder={palate[1].bg} onChange={handleChange} onBlur={handleBlur} value={values.primary}  />
            </div>
            <div className="palatebox">
              <div className="box" style={{background: palate[2].bg, border: palate[2].br}}></div>
              <input type="text" name={palate[2].name} placeholder={palate[2].bg} onChange={handleChange} onBlur={handleBlur} value={values.tartiary}  />
            </div>
            <div className="palatebox">
              <div className="box" style={{background: palate[3].bg, border: palate[3].br}}></div>
              <input type="text" name={palate[3].name} placeholder={palate[3].bg} onChange={handleChange} onBlur={handleBlur} value={values.fourth}  />
            </div>
            </div>
            <div className="submit">
              <button style={{background: Color.primaryColor}} type={`submit`} disabled={isSubmitting}>Guardar cambios</button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
