import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import './dir.scss';
import { useRequestContext } from '../../../hooks/useRequestContext';
import { WLOCreditAPIService } from '../../../services';

interface DireccionProps {
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

const Direccion = (props: DireccionProps) => {
    const {request, setRequest} = useRequestContext();
    const { setPopup } = props;
    const [one] = useState(false)
    const [direccion,setdireccion] = useState({ 
        road: '', 
        mainno: '', 
        suffix: '', 
        secondaryno: '', 
        suffix2: '', 
        complementaryno: '', 
        Complement: '', 
        complementaryno2: '', 
        Complement3: '', 
        complementaryno3: '', 
        Neighborhood: '', 
    })
    useEffect(()=>{
        consultaAddress()
    },[one])
    const consultaAddress = async ()=>{
        let identitif=""
        let typeidenti=""
        identitif=request.numIdentification!
        typeidenti=request.typeIdentification!
        if(request.valoresDireccion)
            {
                if(request.valoresDireccion.identificacion){

                    identitif=request.valoresDireccion.identificacion
                    typeidenti=request.valoresDireccion.tipoindentificacion
                }
         }
            const valorDireccion ={
                Pws_Tip_Consulta: "2",
                Pws_Identificacion: identitif,
                Pws_Tip_Identificacion: typeidenti,
                Pws_Tipo: "",
                Pws_Num_Principal: "",
                Pws_Num_Secundario: "",
                Pws_SufijoPri:"",
                Pws_SufijoSec: "",
                Pws_NumComplementario: "",
                Pws_Complemento1: "",
                Pws_NumComplementario1: "",
                Pws_Complemento2: "",
                Pws_NumComplementario2: "",
                Pws_Barrio: ""
            }
            setRequest({...request,});
        }
    const agregarDireccion = async (valores:any) =>{
        let identitif=""
        let typeidenti=""
        if(request.valoresDireccion){
            identitif=request.valoresDireccion.identificacion
            typeidenti=request.valoresDireccion.tipoindentificacion
         }else{
            identitif=request.numIdentification!
            typeidenti=request.typeIdentification!
         }
        const valorDireccion ={
            Pws_Tip_Consulta: "1",
            Pws_Identificacion: identitif,
            Pws_Tip_Identificacion:  typeidenti,
            Pws_Tipo: valores.road,
            Pws_Num_Principal: valores.mainno,
            Pws_Num_Secundario: valores.secondaryno,
            Pws_SufijoPri:valores.suffix,
            Pws_SufijoSec: valores.suffix2,
            Pws_NumComplementario: valores.complementaryno,
            Pws_Complemento1: valores.Complement,
            Pws_NumComplementario1: valores.complementaryno2,
            Pws_Complemento2: valores.Complement3,
            Pws_NumComplementario2: valores.complementaryno3,
            Pws_Barrio: valores.Neighborhood,
        }
    
        setRequest({
            ...request,
            direcciones:   { 
                road: valores.road, 
                mainno: valores.mainno, 
                suffix: valores.suffix, 
                secondaryno: valores.secondaryno, 
                suffix2:valores.suffix2, 
                complementaryno: valores.complementaryno, 
                Complement: valores.Complement, 
                complementaryno2: valores.complementaryno2, 
                Complement3:valores.Complement3, 
                complementaryno3:valores.complementaryno3, 
                Neighborhood: valores.Neighborhood, 
            }
        });
        setPopup("setPopup");
    }
    const closeOpion=() =>{
        setPopup("close")
        setRequest({
            ...request,
            valoresDireccion: {}
        })

    }
    return(
        <>
            <div onClick={()=>closeOpion()} className="backLayer"></div>
            <div className="modals" style={{top: window.scrollY + 100 + `px`}}>
                <div className="header">
                    <h4>Crear dirección</h4>
                </div>
                <div className="body">
                    <Formik
                enableReinitialize={true} 
                initialValues={direccion}
                        onSubmit={(values, { setSubmitting }) => {
                            agregarDireccion(values)
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) =>{ 
                            return (
                            <form onSubmit={handleSubmit}>
                                <ul>
                                    <li className="w-50">
                                        <label htmlFor="road">{`Tipo (calle, carrera, diagonal, etc.):*`}</label>
                                        <input 
                                            type="text" 
                                            name="road"                              
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.road}
                                            placeholder="Enter"
                                            />
                                    </li>
                                    <li className="w-30">
                                        <label htmlFor="mainno">Nº principal*</label>
                                        <input 
                                            type="number" 
                                            name="mainno" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mainno}
                                            placeholder="#"
                                            />
                                    </li>
                                    <li className="w-20">
                                        <label htmlFor="suffix">Sufijo:</label>
                                        <input 
                                            type="text" 
                                            name="suffix" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.suffix}
                                            placeholder="Enter"
                                        />
                                    </li>
                                </ul>
                                <ul>
                                    <li className="w-30">
                                        <label htmlFor="secondaryno">{`No secundaria *`}</label>
                                        <input 
                                            type="number" 
                                            name="secondaryno"                              
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.secondaryno}
                                            placeholder="#"
                                            />
                                    </li>
                                    <li className="w-40">
                                        <label htmlFor="suffix2">Sufijo</label>
                                        <input 
                                            type="text" 
                                            name="suffix2" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.suffix2}
                                            placeholder="#"
                                            />
                                    </li>
                                    <li className="w-30">
                                        <label htmlFor="complementaryno">nº complementario*</label>
                                        <input 
                                            type="number" 
                                            name="complementaryno" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.complementaryno}
                                            placeholder="Enter"
                                        />
                                    </li>
                                </ul>
                                <ul>
                                    <li className="w-70">
                                        <label htmlFor="Complement">{`Complementar`}</label>
                                        <input 
                                            type="text" 
                                            name="Complement"                              
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Complement}
                                            placeholder="Enter"
                                            />
                                    </li>
                                    <li className="w-30">
                                        <label htmlFor="complementaryno2">Nº complementario*</label>
                                        <input 
                                            type="number" 
                                            name="complementaryno2" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.complementaryno2}
                                            placeholder="Enter"
                                        />
                                    </li>
                                </ul>
                                <ul>
                                    <li className="w-70">
                                        <label htmlFor="Complement3">{`Complementar`}</label>
                                        <input 
                                            type="text" 
                                            name="Complement3"                              
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Complement3}
                                            placeholder="Enter"
                                            />
                                    </li>
                                    <li className="w-30">
                                        <label htmlFor="complementaryno3">Nº complementario.*</label>
                                        <input 
                                            type="number" 
                                            name="complementaryno3" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.complementaryno3}
                                            placeholder="Enter"
                                        />
                                    </li>
                                </ul>
                                <ul>
                                    <li className="w-70">
                                        <label htmlFor="Neighborhood">{`Vecindario`}</label>
                                        <input 
                                            type="text" 
                                            name="Neighborhood"                              
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Neighborhood}
                                            placeholder="Enter"
                                            />
                                    </li>
                                </ul>
                                <div className="submitt">
                                    <button onClick={()=> closeOpion()} className="w-30">
                                        Cancelar
                                    </button>
                                    <button className="w-30" type="submit" disabled={isSubmitting}>
                                        Crear
                                    </button>
                                </div>
                            </form>
                        )}}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default Direccion;