import React from 'react';
import './pdf2.scss';

export const NewPDF2 = () => {
  return (
    <div className='pdfbody2'>
      <div className="head">
          <img className='img-logo-black-sarmiento' src="img/logo-black.png" alt="Cabarcas Sarmineto S.A.S"/>
          <div className="text">
            <ul className="extra">
              <li>Solicitud de Crédito</li>
              <li></li>
            </ul>
            <ul className="extra">
              <li>Fecha</li>
              <li></li>
            </ul>
          </div>
      </div>
      <div className="subhead">
        <ul>
          <li>Punto de Venta:</li>
          <li>Dirección:</li>
        </ul>
        <ul>
          <li>{`Asesor(a) Comercial:`}</li>
        </ul>
      </div>
      <div className="pdfbody">
        <h3>Informacion del Solicitante</h3>
        <ul>
          <li className='w-30'>Identificación</li>
          <li className='w-70'>Expedida en:</li>
        </ul>
        <ul>
          <li className='w-30'>Nombres</li>
          <li className='w-70'></li>
        </ul>
        <ul>
          <li className='w-30'>Dirección</li>
          <li className='w-70'></li>
        </ul>
        <ul>
          <li className='w-10'>Teléfonos</li>
          <li className='w-30'></li>
          <li className='w-10'>Correo</li>
          <li className='w-50'></li>
        </ul>
        <ul>
          <li className="w-10">Estado Civil</li>
          <li className="w-20"></li>
          <li className="w-10">Genero</li>
          <li className="w-20"></li>
          <li className="w-20">Personas A Cargo</li>
          <li className="w-20"></li>
        </ul>
        <ul>
          <li className="w-10">Empleador</li>
          <li className="w-30"></li>
          <li className="w-20">Dirección Trabajo</li>
          <li className="w-40"></li>
        </ul>
        <ul>
          <li className="w-20">Nombre Cónyuge</li>
          <li className="w-40"></li>
          <li className="w-10">Teléfono</li>
          <li className="w-30"></li>
        </ul>
        <ul>
          <li className="w-20">Nombre Madre</li>
          <li className="w-40"></li>
          <li className="w-10">Teléfono</li>
          <li className="w-30"></li>
        </ul>
        <ul>
          <li className="w-10">Valor Patrimonio</li>
          <li className="w-20"></li>
          <li className="w-20">Representado en</li>
          <li className="w-50"></li>
        </ul>
        <ul>
          <li className="w-50 tc">INGRESOS</li>
          <li className="w-50 tc">EGRESOS</li>
        </ul>
        <ul>
          <li className="w-25">Sueldos, arriendos otros</li>
          <li className="w-25"></li>
          <li className="w-25">Gastos Familiares, alimentación y otros</li>
          <li className="w-25"></li>
        </ul>
        <h3>Referencias Personales</h3>
        <ul>
          <li className="w-40">Nombre</li>
          <li className="w-40">Dirección</li>
          <li className="w-20">Teléfono</li>
        </ul>
        <ul>
          <li className="w-100"></li>
        </ul>
        <ul>
          <li className="w-100"></li>
        </ul>
        <h3>Referencias Familiares</h3>
        <ul>
          <li className="w-40">Nombre</li>
          <li className="w-40">Dirección</li>
          <li className="w-20">Teléfono</li>
        </ul>
        <ul>
          <li className="w-100"></li>
        </ul>
        <h3>Codeudor 2</h3>
        <ul>
          <li className="w-30">Identificación</li>
          <li className="w-70">Expedida en:</li>
        </ul>
        <ul>
          <li className="w-25">Nombres</li>
        </ul>
        <ul>
          <li className="w-25">Dirección</li>
          <li className="w-75"></li>
        </ul>
        <ul>
          <li className="w-10">Teléfonos</li>
          <li className="w-30"></li>
          <li className="w-10">Correo</li>
          <li className="w-50"></li>
        </ul>
        <ul>
          <li className="w-10">Estado Civil</li>
          <li className="w-20"></li>
          <li className="w-10">Genero</li>
          <li className="w-20"></li>
          <li className="w-20">Personas A Cargo</li>
          <li className="w-20"></li>
        </ul>
        <ul>
          <li className="w-10">Empleados</li>
          <li className="w-30"></li>
          <li className="w-20">Dirección Trabajo</li>
          <li className="w-40"></li>
        </ul>
        <ul>
          <li className="w-20">Nombre Cónyuge</li>
          <li className="w-50"></li>
          <li className="w-10">Teléfono</li>
          <li className="w-20"></li>
        </ul>
        <ul>
          <li className="w-20">Nombre Madre</li>
          <li className="w-50"></li>
          <li className="w-10">Teléfono</li>
          <li className="w-20"></li>
        </ul>
        <ul>
          <li className="w-20">Valor Patrimonio</li>
          <li className="w-30"></li>
          <li className="w-20">Representado en</li>
          <li className="w-30"></li>
        </ul>
        <ul>
          <li className="w-50">INGRESOS</li>
          <li className="w-50">EGRESOS</li>
        </ul>
        <ul>
          <li className="w-25">Sueldos, arriendos otros</li>
          <li className="w-25">0</li>
          <li className="w-25">Gastos Familiares, alimentación y otros</li>
          <li className="w-25">0</li>
        </ul>
        <h3>Referencias Personales</h3>
        <ul>
          <li className="w-40">Nombre</li>
          <li className="w-40">Dirección</li>
          <li className="w-20">Teléfono</li>
        </ul>
        <ul>
          <li className="w-100"></li>
        </ul>
        <ul>
          <li className="w-100"></li>
        </ul>
        <h3>Referencias Familiares</h3>
        <ul>
          <li className="w-40">Nombre</li>
          <li className="w-40">Dirección</li>
          <li className="w-20">Teléfono</li>
        </ul>
        <ul>
          <li className="w-100"></li>
        </ul>
        <ul>
          <li className="w-100"></li>
        </ul>
        <ul>
          <li className="w-10">Articulo:</li>
          <li className="w-20"></li>
          <li className="w-40"></li>
          <li className="w-20">Modelo</li>
          <li className="w-10"></li>
        </ul>
        <ul>
          <li className="w-10">Valor Contado:</li>
          <li className="w-20"></li>
          <li className="w-10">Cuota Inicial:</li>
          <li className="w-20"></li>
          <li className="w-10">Plazo</li>
          <li className="w-10"></li>
          <li className="w-10">Valor Cuotas</li>
          <li className="w-10"></li>
        </ul>
        <h6>AUTORIZACIÓN PARA CONSULTAR, COMPARTIR INFORMACION CON CENTRALES DE RIESGO, TRATAMIENTO DE DATOS PERSONALES, VISITAS DOMICILIARIAS, NOTIFICAR POR CUALQUIER MEDIO Y DECLARACION VOLUNTARIA DE ORIGEN Y DESTINO DE FONDOS, REALIZAR CONSULTAS EN LISTAS VINCULANTES.</h6>
        <p>{`En mi calidad de titular de la información, actuando libre y voluntariamente, autorizo a CABARCAS SARMIENTO SAS, NIT 900331740-7, o a quien represente sus
          derechos, con domicilio principal en la Cra 3 No 37-71, en Montería (Córdoba), teléfono 3235996414, para consultar a través de cualquier fuente, realizar por cualquier medio 
          la comunicación previa al reporte de información negativa y reportar a Datacredito o en cualquier otra central de información de riesgo, las informaciones y referencias 
          relativas a mi nombre y/o la sociedad que represento, comportamiento y crédito comercial, hábitos de pago, manejo de cuenta (s) y en general, al cumplimientode mis
          obligaciones pecuniarias. Igualmente en mi calidad de Titular de la información, actuando libre y voluntariamente, autorizo a CABARCAS SARMIENTO SAS, oquien 
          represente sus derechos, y/o a Experian Colombia S.A. (en adelante DataCrédito Experian), Carrera 7 No. 76- 35 en Bogotá D.C., tel 3191400, o quien represente sus 
          derechos, a acceder a mis datos personales contenidos en la base de datos Aportes en Línea (Aportes En Línea S.A.900147238-2), Colfondos y/o administradoras de
          pensiones y demás Operadores de información de seguridad social autorizados por el Ministerio de Salud y Protección Social, a mis datos personales recolectados por medio 
          del presente formulario y a mis datos personales contenidos en la base de datos de DataCrédito Experian (Operador de información crediticia), en adelante mi información 
          personal, para darle tratamiento en los términos expresados en la Política de Tratamiento de la Información Personal de CABARCAS SARMIENTO SAS, o quien represente 
          sus derechos y/o DataCrédito Experian, y para finalidades de gestión de riesgo crediticio tales como: (1) elaboración y circulación a terceros de scores crediticios, 
          herramientas de validación de ingresos, herramientas predictivas de ingresos, herramientas para evitar el fraude y en general, herramientas que le permitan a los Suscriptores 
          de DataCrédito Experian, adelantar una adecuada gestión del riesgo crediticio. (2) Compararla, contrastarla y complementarla con la información financiera, comercial, 
          crediticia, de servicios y proveniente de terceros países de DataCrédito Experian. (3) Compararla, contrastarla y complementarla con la información personal de Mi
          datacrédito. Declaro además, que entrego de forma libre, voluntaria y doy mi autorización expresa para que CABARCAS SARMIENTO SAS, identificadacon Nit 900331740-
          7, en lo sucesivo "La Compañía", actuando como responsable del tratamiento de los datos personales y considerando lo dispuesto por la ley 1581 de 2012, el decreto
          reglamentario 1377 de 2013, decreto único 1074 de 2015 y demás normas que los adicionen, modifiquen o deroguen y al manual de políticas deprotección de datos 
          personales, diseñados por la compañía, trate mis Datos Personales, con la finalidad de medir niveles de satisfacción, informar sobre campañas de servicio, comunicar 
          campañas promocionales, realizar encuestas, realizar recordatorio para mantenimientos, ejecutar campañas de fidelización, enviar invitaciones a eventos, rifas, realizar 
          actualización de datos, invitar a pruebas de vehículos, ofrecimiento de productos y servicios, comunicar noticias de las marcas que comercialicen y de la red de ventas y 
          servicios, comunicar información relacionada con productos para la financiación en la adquisición de bienes y servicios y para realizar inspecciones domiciliarias, registros 
          de evidencias y verificación de los datos por mi suministrados para el trámite del crédito. Así mismo declaro, de manera libre y voluntaria y dando certeza de que todo lo aquí 
          consignado es cierto, que mis recursos (o los recursos de la persona natural o jurídica que represento) provienen de actividades lícitas y están ligados al desarrollo normal 
          de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma 
          que lo sustituya, adicione o modifique. Expresa e irrevocablemente también autorizo a CABARCAS SARMIENTO SAS, identificada con Nit 900331740-7, o a quien represente 
          sus derechos: (a) Para que realice las consultas y controles establecidos en los términos de su política de prevención de lavado de activos y financiación al terrorismo para 
          verificar en las fuentes que considere necesarias, la información suministrada en el presente documento, tales como los listados dela OFAC (Office of Foreign Assets Control) 
          o de las Naciones Unidas. (b) Para efectuar los reportes a las autoridades competentes en materia de lavado de activos y financiación al terrorismo, que considere necesarios 
          realizar de conformidad con sus reglamentos y manuales de prevención y/o administración del riesgo de lavado de activos y financiación al terrorismo. Que eximo a 
          CABARCAS SARMIENTO SAS, identificada con Nit 900331740-7, de toda responsabilidad que se derive por información errónea, falsa o inexacta, que yo hubiese 
          proporcionado en este documento o de la violación del mismo.`}</p>
          <div className="trademark">
            <div className="signed">
              <div className="sign">
                <div className="signature"></div>
                <p>Firma Solicitante</p> 
                <p>C.C.No</p>
              </div>
            </div>
            <div className="signed">
              <div className="sign">
                <div className="signature blk"></div>
                <p style={{visibility: 'hidden'}}>Firma Solicitante</p> 
                <p style={{visibility: 'hidden'}}>C.C.No</p>
              </div>
            </div>
            <div className="signed">
              <div className="sign">
                <div className="signature"></div>
                <p>Firma Deudor</p> 
                <p>C.C.No</p>
              </div>
            </div>
            <div className="signed">
              <div className="sign">
                <div className="signature blk"></div>
                <p style={{visibility: 'hidden'}}>Firma Solicitante</p> 
                <p style={{visibility: 'hidden'}}>C.C.No</p>
              </div>
            </div>
            <div className="signed">
              <div className="sign">
                <div className="signature"></div>
                <p>Firma Deudor</p> 
                <p>C.C.No</p>
              </div>
            </div>
            <div className="signed">
              <div className="sign">
                <div className="signature blk"></div>
                <p style={{visibility: 'hidden'}}>Firma Solicitante</p> 
                <p style={{visibility: 'hidden'}}>C.C.No</p>
              </div>
            </div>
          </div>
          <p>{`Recuerde que podrá ejercer los derechos que la ley prevé, siguiendo los procedimientos de la compañía, los cuales se encuentran disponibles en
            nuestro Manual de Políticas Y Procedimientos de Protección de Datos Personales, que podrá consultar en la página web:
            http://www.cabarcassarmientosas.com`}</p>
      </div>
    </div>
  )
}
