import React from 'react';
import './is.scss';
import { ArrowDown } from '../../../assets/icons/component';
import {Field} from 'formik';


interface InputProp {
    dir: string;
    type: string;
    label: string;
    errors:any;
    name: string;
    placeholder: string;
}
export const Input : React.FC <InputProp> = ({dir, type,errors, label, name, placeholder}) => {
  return (
    <li className={`input ${dir === 'goDown'?'down':''}`}>
        <label>{label}</label>
        <Field id={name}
                           name={name}
                           type={type}
                           placeholder={placeholder}
                           className= {`${errors[name] ? 'input-error' : ''}`}/>
              
    </li>
  )
}

interface SelectProp {
    dir: string;
    label: string;
    name: string;
    errors:any;
    options: any[];
}
export const Select : React.FC <SelectProp> = ({dir,errors, label, name, options}) => {
  return (
    <li className={`select ${dir === 'goDown'?'down':''}`}>
        <label>{label}</label>
        <ArrowDown stroke={`#4A4A4A`} />
        <Field as="select"
                           id={name}
                           name={name}
                           className= {`${errors[name] ? 'input-error' : ''}`}>
                           <option  value={''}>Seleccionar</option>
                        {
                options.map((option, i)=>{
                    return(
                        <option key={i} value={option.option}>{option.name.toUpperCase()}</option>
                    )
                })
            }
        </Field>
        
    </li>
  )
}

export const Input2 : React.FC <InputProp> = ({dir, type, label, name, placeholder}) => {
  return (
    <li className={`input ${dir === 'goDown'?'down':''}`}>
        <label>{label}</label>
        <input type={type} name={name} placeholder={placeholder} />
    </li>
  )
}

export const Select2 : React.FC <SelectProp> = ({dir, label, name, options}) => {
  return (
    <li className={`select ${dir === 'goDown'?'down':''}`}>
        <label>{label}</label>
        <ArrowDown stroke={`#4A4A4A`} />
        <select name={name}>
            {
                options.map((option, i)=>{
                    return(
                        <option key={i} value={option.option}>{option.option}</option>
                    )
                })
            }
        </select>
    </li>
  )
}