import React, {useState} from 'react';
import { MenuBar } from '../../components/shared/Menubar/Menubar';
import { OTPFunc } from '../../components/shared/Otpfunc';
import { Popup } from '../../components/shared/Popup';
import './otp.scss';

export const OTP = () => {
    const [otp, setOtp] = useState(false);
  return (
    <>
        {
            otp
            ?<Popup setfunc={setOtp} Body={<OTPFunc setfunc={setOtp}/>} />
            :<></>
        }
        <MenuBar />
        <div className='otp'>
            <h4 className="redhead">Previsualización del documento a firmar</h4>
            <div className="doc">
                <div className="document">
                    <p>DOCUMENTO</p>
                </div>
            </div>
            <div className="submit">
                <button onClick={()=>setOtp(true)} >Firmar</button>
            </div>
        </div>
    </>
  )
}
