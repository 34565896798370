import React from 'react';
import { Edit } from '../../../../assets/icons/component';
import { Input, Select } from '../../InputSelet';
import './addEdit.scss';
import {Field, Form, Formik} from 'formik';

const Info = [
    {
        info1: 'Código',
        info2: 'Atributo',
        info3: 'Descripción',
        color1: 'red',
        color2: 'red',
        color3: 'red',
    },
    {
        info1: '1',
        info2: 'Color',
        info3: 'Rojo',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        info3: '220',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        info3: '2021',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '1',
        info2: 'Color',
        info3: 'Rojo',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        info3: '220',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        info3: '2021',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '1',
        info2: 'Color',
        info3: 'Rojo',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        info3: '220',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        info3: '2021',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '1',
        info2: 'Color',
        info3: 'Rojo',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        info3: '220',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        info3: '2021',
        color1: 'red',
        color2: 'red',
        color3: '',
    },
]

interface ItemProp {
    item : {
        info1: string,
        info2: string,
        info3: string,
        color1: string,
        color2: string,
        color3: string,
    };
}

export const Item : React.FC <ItemProp> = ({item}) => {
    return (
        <ul>
            <li className={`${item.color1}`}>{item.info1}</li>
            <li className={`${item.color2}`}>{item.info2}</li>
            <li className={`${item.color3}`}>{item.info3}</li>
        </ul>
    );
}

export const AddEditInfo = () => {
    const options =[
        {
            option: 'Tipo de producto1'
        },
        {
            option: 'Tipo de producto2'
        },
        {
            option: 'Tipo de producto3'
        },
    ]
    
  const handleSubmit =async (values: any) => {
    console.log(values)
}
  return (
    <div className="editInfo">
           <Formik
                    initialValues={{
                      Pws_Tip_perfil: '',
                            Pws_Tip_oficina: '',
                            Pws_Tip_identificacion: '',
                            Pws_identificacion: '',
                            Pws_Tip_cargo: '',
                            Pws_clave: '',
                            Pws_clave2: ''
                    }}
                    onSubmit={handleSubmit}
                >
                    {({errors, isSubmitting}) =>{
                      
                      return (
                        <>
                            <Form>
        <div className="image">
            <img src="img/modelo-1.png" alt="Moto" />
            <div className="subtitle">foto de categoria</div>
            <button>Cambiar foto</button>
        </div>
        <div className="info">
            <ul>
                <Input dir={`goDown`} type={`text`} label={`Nombre del producto`} name={`Nombre_del_producto`} placeholder={`Nombre del producto`} errors={errors}/>
                <Input dir={`goDown`} type={`text`} label={`Stock`} name={`Stock`} placeholder={`Stock`} errors={errors}/>
            </ul>
            <ul>
                <Input dir={`goDown`} type={`text`} label={`Valor comedrcial de la moto`} name={`Valor_comedrcial_de_la_moto`} placeholder={`Valor comedrcial de la moto`} errors={errors}/>
                <Input dir={`goDown`} type={`text`} label={`Valor unitario de la moto`} name={`Valor_unitario_de_la_moto`} placeholder={`Valor unitario de la moto`} errors={errors}/>
            </ul>
            <ul>
                {/* <Select dir={`goDown`} label={`Tipo de producto`} name={`Tipo de producto`} options={options} errors={errors}/> */}
            </ul>
            <div className="attributos">
                <p>Atributos</p>
                <div className="table">
                    <div className="edit">
                        <span style={{cursor: 'pointer'}}><Edit /></span>
                    </div>
                    <div className="table-body">
                        <div className="body">
                            {
                                Info.map((item, i)=>{
                                    return (
                                        <Item item={item} key={i} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="submit">
                    <button style={{background: '#000000', color: '#fff', padding: '5px 10px', borderRadius: '9px'}}>Guardar cambios</button>
                </div>
            </div>
        </div>
        </Form>
        </>
                    )
                    
}
                    
                    }
                </Formik>
    </div>
  )
}
