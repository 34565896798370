import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import { AdminDoc } from '../../components/shared/UserDocument/AdminDoc';

export const AdminPerfil = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <AdminDoc />
    </>
  )
}
