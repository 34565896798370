import React, { useEffect, useState } from 'react';
import './ddc.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import {DetailCredit} from '../../components/shared/DetailCredit/DetailCredit';
import Direccion from '../../components/shared/modal/Direccion';
import { Popup } from '../../components/shared/Popup';
import { Pagos } from '../../components/shared/Pagosplan.tsx';
export const DetalleDelCredito = () => {
    
  const [popup, setPopup] = useState("close");
  const [plan, setPlan] = useState(false);
  const [datosP, setDatosP] = React.useState<any>();
  const handleDatosPChange = (newDatosP : any) => {
    setDatosP(newDatosP);
  };

  return (
    <>
    
          {
            popup === "open"
            ?<>
                <Direccion setPopup={setPopup}/>
            </>
            :<></>
          }
          {
            plan
            ?<Popup setfunc={setPlan} Body={<Pagos  datosP={datosP} />} />
            :<></>
          }
      <ResponsiveAppBar />
      <DetailCredit setPopup={setPopup} setPlan={setPlan} setDatosP={handleDatosPChange}/>
    </>
  )
}
