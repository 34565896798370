import React, { useEffect, useState } from "react";
import {useIntl} from "react-intl";
import { useNavigate,useLocation } from "react-router-dom";
import {DocumentDemo} from "../../assets/icons";
import { useSignContext } from "../../hooks/useSignContext";
import { Document,pdfjs,Page  } from 'react-pdf'
import { WLOCreditAPIService } from "../../services";
import {AppRoutes} from "../../Router";
import { useRequestContext } from "../../hooks/useRequestContext";
import ClockLoader from "react-spinners/ClockLoader";
import { toast } from "react-hot-toast";
import { Home } from "../../views";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function PDFViewSign() {
    let buscar = useLocation();
    const {sign, setSign} = useSignContext();
    const {request, setRequest} = useRequestContext();
    const navigate = useNavigate();
    const intl = useIntl();
    const [details, setDetails] = useState(null) as any;
    const [numPages, setNumPages] = useState(null);
    const [sargilat, setSargilat] = useState(false);
    const [estudio, setEstudio] = useState(false);
    const [negado, SetDataNegado] = useState(false);
    const [DataCreditoCodeudor, setDataCreditoCodeudor] = useState(false);
    const [documento, setDocumento] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false);

    const onDocumentLoadSuccess = (numPagess:any) => {
        setNumPages(numPagess._pdfInfo.numPages)
    };

    useEffect( () => {
        if(!documento){ 
          setIsLoading(true)
            WLOCreditAPIService.getDocumento({ Pws_num_solicitud: request.numSol, Pws_Identificacion: request.personCygnus?.Pws_Identificacion,Pws_Tip_Identificacion: request.personCygnus?.Pws_Tip_Identificacion,otp:sign.otp}).then((valor)=>{
                setDocumento(valor.payload)
                saveDocument(valor.payload)
                setIsLoading(false)
             })
        }
      }, [documento, setDocumento])
      useEffect(() => {
          getUserGeolocationDetails();
      }, []);
      useEffect(()=>{
        if(buscar){
          var valorBusqueda=buscar.search.replace("?","")
          var valorBusqueda2=valorBusqueda.split("-")
          console.log('valorBusqueda ', valorBusqueda , ' valorBusqueda2 ', valorBusqueda2);
          if(Number(valorBusqueda2[0])===0){
            setSargilat(false)
          }else{
            setSargilat(true)
          }
          if(Number(valorBusqueda2[1])===0){
            SetDataNegado(false)
          }else{
            SetDataNegado(true)
          }
          if(Number(valorBusqueda2[2])===0){
            setDataCreditoCodeudor(false)
          }else{
            setDataCreditoCodeudor(true)
          }
          if(Number(valorBusqueda2[3])===0){
            setEstudio(false)
          }else{
            setEstudio(true)
          }
          
        }
      },[buscar]);
      useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = 'Se perderá el progreso actual si sales de esta página.';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    const getUserGeolocationDetails = () => {
      fetch("https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572")
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => setDetails(data))
        .catch(error => {
          console.error('Error fetching location data:', error);
        });
    };
    const saveDocument = async (base64:any)=>{
      const solicitudLocal = JSON.parse(localStorage.getItem('solicitud')!);
      const numIdentificacion = localStorage.getItem('identificacion');
      const tipIdentificacion = localStorage.getItem('tipoidentificacion');
      const documentRequest= {
        Pws_num_solicitud: solicitudLocal.solicitudnum!,
        Pws_Identificacion: numIdentificacion!,
        Pws_Tip_Identificacion: tipIdentificacion!,
        Pws_Doc_Tipo: "12",
        Pws_Doc_nombre: 'Documento_Firmado.pdf',
        Pws_Doc_estado: "1",
        Pws_Doc_ruta: "1",
        Pws_Clave_doc: base64,
      };
      const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);      
    }
    //Funcion de descarga del documento
    const gotodata= async ()=>{
      //Verifica si hay un documento para descargar 
      if (!documento) {
        toast.error("No hay documento para descargar");
        return;
    }
    //Crea un enlace temporal para cuando inicia la descarga 
        const dataCodeudor = localStorage.getItem('propietario');
        const dataRequerido = localStorage.getItem('Requerido');
        const link = document.createElement('a');
        link.href = documento;
        link.href = documento;
        link.download = 'Documento_Firmado.pdf'; 
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        //   const solicitudLocal = JSON.parse(localStorage.getItem('solicitud')!);
        //   const numIdentificacion = localStorage.getItem('identificacion');
        //   const tipIdentificacion = localStorage.getItem('tipoidentificacion');
        //   const documentRequest= {
        //     Pws_num_solicitud: solicitudLocal.solicitudnum!,
        //     Pws_Identificacion: numIdentificacion!,
        //     Pws_Tip_Identificacion: tipIdentificacion!,
        //     Pws_Doc_Tipo: "12",
        //     Pws_Doc_nombre: 'Documento_Firmado.pdf',
        //     Pws_Doc_estado: "1",
        //     Pws_Doc_ruta: "1",
        //     Pws_Clave_doc: documento,
        // };
        // const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
        // link.setAttribute(
        //   'download',
        //   Archivo.pdf,
        // );
        link.setAttribute("target","_blank")
        // Append to html link element page
        document.body.appendChild(link);
        

        // Start download
        link.click();
        let tipo=""
        let tipo1=""
        let tipo2=""
        let tipo3=""
        if(sargilat){
            tipo="1"
        }else{
            tipo="0"
        }
        if(negado){
            tipo1="1"
        }else{
            tipo1="0"
        }
        if(DataCreditoCodeudor){
            tipo2="1"
        }else{
            tipo2="0"
        }
        if(estudio){
            tipo3="1"
        }else{
            tipo3="0"
        }
       if (dataCodeudor=='true') {
         if (tipo == "0") {
          toast.success('su credito fue aprobado')
          const datas={
            id:"hola",
            number:request.numSol!,
            deseasMoto: true ,
            email:sign.email!,
            typeIdent:sign.typeIdentification!,
            numbIdent:sign.numIdentification!,
            codeudorReq:DataCreditoCodeudor,
            Negado:false ,
            sargilaft:false,
            enestudio:false,
            cupo:" ",
          }
          const response =  WLOCreditAPIService.getEmailPropietario(datas);
          toast.success("su credito fue aprobado")
          navigate(AppRoutes.SIGN)
         }else if(tipo == "1"){
          const datas={
            id:"hola",
            number:request.numSol!,
            deseasMoto: false ,
            email:sign.email!,
            typeIdent:sign.typeIdentification!,
            numbIdent:sign.numIdentification!,
          }
          // const response =  WLOCreditAPIService.getEmailPropietario(datas);
          // toast.error("su credito fue rechazado")
          navigate(AppRoutes.SIGN)
         }
        }
       else if (dataRequerido == "2") {
         if (tipo == "0" && tipo1 == "0") {
          toast.success('su credito fue aprobado')
          const datas={
            id:"hola",
            number:request.numSol!,
            deseasMoto: true ,
            email:sign.email!,
            typeIdent:sign.typeIdentification!,
            numbIdent:sign.numIdentification!,
            codeudorReq:DataCreditoCodeudor,
            Negado:false ,
            sargilaft:false,
            enestudio:false,
            cupo:" ",
          }
          const response =  WLOCreditAPIService.getEmailPropietario(datas);
          toast.success("su credito fue aprobado")
          navigate(AppRoutes.SIGN)
         }else if(tipo == "1" || tipo1 == "1"){
          const datas={
            id:"hola",
            number:request.numSol!,
            deseasMoto: false ,
            email:sign.email!,
            typeIdent:sign.typeIdentification!,
            numbIdent:sign.numIdentification!,
          }
          // const response =  WLOCreditAPIService.getEmailPropietario(datas);          
          // toast.error("su credito fue rechazado")
          navigate(AppRoutes.SIGN)
         }
       } else {
        navigate(AppRoutes.SARGILAT+"?"+tipo+"-"+tipo1+"-"+tipo2+"-"+tipo3, {replace: true})
       }
       


    }
    return (
        <div className="row py-0 px-4 gx-0">
          <ClockLoader id='spinner' color={"#d72222"} loading={isLoading} size={100}/>
            <div className="row p-0 m-0">
                <br/>
                <h1 className="title-red">{'VISUALIZACIÓN DEL DOCUMENTO FIRMADO'}</h1>
                <br/>
                <br/>  
                 {!documento?<>
                    <DocumentDemo />
                </>:<>
                
                <div className="all-page-container">
                  <Document
                  file={documento} 
                  options={{ workerSrc: "/pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                     <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
                  </div>
                  </>}

                <div className="row justify-content-center mt-3">
                  <div className="col-lg-6 text-center text-dark">
                  </div>
                </div>
                <div className="col">
                    <br/><br/><br/>
                    <div className={"form-know-you-item-button"}  onClick={gotodata}>
                        {'Descargar Documento'}
                    </div>
                </div>
            </div>
        </div>
    )
}