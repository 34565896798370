import React, { useEffect } from 'react';
import { Delete, Edit } from '../../../../assets/icons/component';
import './addEdit.scss';
import { useLocation } from 'react-router-dom';
import { InventoryService } from '../../../../services';

const Info = [
    {
        info1: '1',
        info2: 'Color',
        color1: 'red',
        color2: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        color1: 'red',
        color2: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        color1: 'red',
        color2: '',
    }
]

interface ItemProp {
    item : {
        R_s_cod_atr: string,
        R_s_nom_atr: string,
    };
}

export const Item : React.FC <ItemProp> = ({item}) => {
    return (
        <ul>
            <li className={'red'}>{item.R_s_cod_atr}</li>
            <li className={''}>{item.R_s_nom_atr}</li>
            <li style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}> <Edit /> <Delete /></li>
        </ul>
    );
}

export const Attribute = () => {
    const [atributtes, setAtributtes] = React.useState([] as any);

    useEffect(()=>{
        allAttribute();   
    },[]);

    const allAttribute= async ()=>{
        const valorProduct= await InventoryService.postAttributesQuery({
            pws_cod_atr:"",
        });
        console.log(valorProduct) 
        if(valorProduct.payload.data.RespDetAtr != null){
            setAtributtes(valorProduct.payload.data.RespDetAtr.RespuestaDetAtri) 
            console.log(atributtes)    
        }    
    }

  return (
    <div className="editInfo attribute">
        <div className="info">
            <div className="attributos">
                <h4>Parametrización atributos</h4>
                <div className="table">
                    <div className="edit">
                        <span style={{cursor: 'pointer'}}><Edit /></span>
                    </div>
                    <div className="table-body">
                        <div className="body">
                        <ul>
                            <li className={`red`}>Código</li>
                            <li className={`red`}>Atributo</li>
                            <li className={`red`}>Acciones</li>
                        </ul>
                            {
                                 atributtes && atributtes.map((item:any, i:any)=>{
                                    return (
                                        <Item item={item} key={i} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="submit">
                    <button style={{background: '#D0021B', color: '#fff', padding: '5px 10px', borderRadius: '9px'}}>+</button>
                    <button style={{background: '#4a4a4a', color: '#fff', padding: '5px 10px', borderRadius: '9px'}}>Guardar cambios</button>
                </div>
            </div>
        </div>
    </div>
  )
}
