import {Modal} from "react-bootstrap";
import {Field} from "formik";
import {useIntl} from "react-intl";
import {AdminService, WLOCreditAPIService} from "../../../../services";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useLoginContext} from "../../../../hooks/useLoginContext";
import {useRequestContext} from "../../../../hooks/useRequestContext";
interface ModalReferences {
    show: boolean;
    onHide: () => void;
    valuesperson?: any;
    values?: any;
    solicitud?: any;
}

export function ModalReferencesCodeudor(props: ModalReferences) {
    const {show, onHide, values, solicitud,valuesperson} = props;
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [one] = useState(false)
    const [listReferencias,setListReferen] = useState([])
    
   useEffect(()=>{
    getAll()
   },[one])
    const getAll= async ()=>{
        const refer= await AdminService.getReferencias();
            if(refer.payload.data.ListRef.Datos_Referencias.length>0){
                setListReferen(refer.payload.data.ListRef.Datos_Referencias)
            }
    }
    const handleSaveReference = async (e: any) => {
        e.preventDefault();
            const references: any = await WLOCreditAPIService.addReferences({
                s_num_solicitud: solicitud,
                s_identificacion: valuesperson.Pws_Identificacion,
                s_tipo_identificacion: valuesperson.Pws_Tip_Identificacion,
                s_ref_tipo: valuesperson.s_ref_tipo,
                s_ref_parentes: valuesperson.s_ref_parentes,
                s_ref_nomcomple: valuesperson.s_ref_nomcomple,
                s_ref_telfij: valuesperson.s_ref_telfij,
                s_ref_telcel: valuesperson.s_ref_telcel
                // s_ref_tipo: "1",
                // s_ref_parentes: "1",
                // s_ref_nomcomple: "Hola",
                // s_ref_telfij: "123456",
                // s_ref_telcel: "123456"
            })
            if (references.payload.result === "1") {
                toast.success('Se agregado la referencia de manera exitosa');
                valuesperson.s_ref_tipo ="";
                valuesperson.s_ref_parentes ="";
                valuesperson.s_ref_nomcomple ="";
                valuesperson.s_ref_telfij ="";
                valuesperson.s_ref_telcel ="";
                onHide();
            }else{
                toast.success(references.payload.message);
            }            
     
    }

    const intl = useIntl();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="title-modal-spouse">
                        {'Información de la referencia'}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "relationship"})}</div>
                            <Field as="select"
                                   id={"s_ref_parentes"}
                                   className={'form-know-you-item-input-medium'}
                                   name="s_ref_parentes">
                                <option>{intl.formatMessage({id: "relationship"})}</option>
                                {listReferencias.map((valor:any)=>{
                                    return(<>
                                <option value={valor.s_codigo}>{valor.s_descripcion_legal}</option>
                                    </>)
                                })}
                            </Field>
                        </div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{"Tipo de referencia"}</div>
                            <Field as="select"
                                   id={"s_ref_tipo"}
                                   className={'form-know-you-item-input-medium'}
                                   name="s_ref_tipo">
                                <option>{'Tipo de referencia'}</option>
                                <option value={'1'}>{'FAMILIAR'}</option>
                                <option value={'2'}>{'PERSONAL'}</option>
                            </Field>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{'Nombre completo'}</div>
                            <Field id={"s_ref_nomcomple"}
                                   name={"s_ref_nomcomple"}
                                   className={'form-know-you-item-input-xs display-flex'}
                                   placeholder={'Nombre completo'}/></div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_phone_1"})}</div>
                            <Field id={"s_ref_telfij"}
                                   name={"s_ref_telfij"}
                                   className={'form-know-you-item-input-xs display-flex'}
                                   placeholder={intl.formatMessage({id: "placeholder_phone_1"})}/></div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_phone_2"})}</div>
                            <Field id={"s_ref_telcel"}
                                   name={"s_ref_telcel"}
                                   className={'form-know-you-item-input-xs display-flex'}
                                   placeholder={intl.formatMessage({id: "placeholder_phone_2"})}/>
                            <br/>
                        </div>
                        <div className="col"/>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={"form-know-you-item-button"} onClick={props.onHide}>
                                {intl.formatMessage({id: "button_cancel"})}
                            </div>
                        </div>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className={"form-know-you-item-button-gray"} onClick={handleSaveReference}>
                                {intl.formatMessage({id: "button_continue"})}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}