import React, {useState} from 'react';
import './switch.scss';

interface SwitchProp {
    check : boolean;
    text: string;
}

export const Switch : React.FC <SwitchProp>  = ({check, text}) => {
    const [checked, setChecked] = useState (check);
  return (
    <li className='switchParent'>
        <label className='switchText'>{text}</label>
    <label className="switch" onClick={(e)=>{e.preventDefault();setChecked(!checked);}}>
            <input className={`${checked && 'checked'}`} type="checkbox"/>
            <span className="slider round"></span>
        </label>
    </li>
  )
}
