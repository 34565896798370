import React from 'react';
import './dc.scss';
import { CategoryDetail } from '../DetailCatComp/CategoryDetail';
import ResponsiveAppBar from '../../shared/Menubar/ResponsiveAppBar';

export const DetailCategory = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <CategoryDetail />
    </>
  )
}
