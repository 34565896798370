import React, { useEffect, useState } from 'react';
import { Table } from '../Table/Table';
import './pp.scss';

const headers = [
    {
        header: 'Fecha de pago'
    },
    {
        header: 'N° de pago'
    },
    {
        header: 'Intereses'
    },
    {
        header: 'Capital pagado'
    },
    {
        header: 'Estado'
    },
    {
        header: 'Saldo pendiente'
    },
    {
        header: 'Descuento de la empresa'
    },
    {
        header: 'IVA descuento'
    },
    {
        header: 'Seguro aval'
    }
]


interface InfoProps {
    info: {
        item1: string,
        item2: string,
        item3: string,
        item4: string,
        item5: string,
        item6: string,
        item7: string,
        item8: string,
        item9: string,

    };
}

export const Item: React.FC <InfoProps> = ({info}) => {
    return (
        <>
            <ul>
                <li className="listItem firstItem">{info.item1}</li>
                <li className="listItem">{info.item2}</li>
                <li className="listItem">{info.item3}</li>
                <li className="listItem">{info.item4}</li>
                <li className="listItem">{info.item5}</li>
                <li className="listItem">{info.item6}</li>
                <li className="listItem">{info.item7}</li>
                <li className="listItem">{info.item8}</li>
                <li className="listItem">{info.item9}</li>

            </ul>
        </>
    )
}
type PagosProps = {
    datosP: any;
};
export const Pagos = (props: PagosProps) => {
    const {datosP} = props;   
    function formatearNumero(numero: number): string {
        return numero.toLocaleString('es-ES');
    }
    const sumas = datosP.map((pago: any) => {
        const sumaAtr2 = Number(pago.Atributo.Atr.find((atr: any) => atr.s_cod_atr1 === "2")?.s_atr1) || 0;
        const sumaAtr13 = Number(pago.Atributo.Atr.find((atr: any) => atr.s_cod_atr1 === "13")?.s_atr1) || 0;
        const sumaAtr18 = Number(pago.Atributo.Atr.find((atr: any) => atr.s_cod_atr1 === "18")?.s_atr1) || 0;
    
        return {
            sumaAtr2,
            sumaAtr13,
            sumaAtr18,
        };
    });
    const atributos = datosP.map((pago: any) => {
        const seguroAval = Number(pago.Atributo.Atr.find((atr: any) => atr.s_cod_atr1 === "21")?.s_atr1) || 0;
        const ivaSeguroAval = Number(pago.Atributo.Atr.find((atr: any) => atr.s_cod_atr1 === "24")?.s_atr1) || 0;
    
        return {
            seguroAval,
            ivaSeguroAval,
        };
    });
    const data = datosP.map((pago: any, index: number) => ({
        item1: pago.s_fecha_pago_pac.split('-').slice(0, 3).reverse().join('/'),
        item2: pago.s_cuota,
        item3: formatearNumero(sumas[index].sumaAtr2 + sumas[index].sumaAtr13 + sumas[index].sumaAtr18),
        item4: formatearNumero(Number(pago.s_capital_pagado)),
        item5:  pago.s_estado_cuo,
        item6: formatearNumero(Number(pago.s_saldo_pen)),
        item7: pago.s_desc_emp == "" ? 0 : pago.s_desc_emp ,
        item8: formatearNumero(Number(atributos[index].ivaSeguroAval)),
        item9: formatearNumero(Number(atributos[index].seguroAval))
    }));
    return (
    <div className='pagos'>
        <h4 className="redhead">Plan de pagos</h4>
        <div className="table">
            <Table TableItem={Item} headers={headers} data={data}/>
        </div>
        {/* <div className="submit">
            <button type='submit'>Aceptar</button>
        </div> */}
    </div>
    )
}
