import React, {useEffect, useState} from 'react';
import './pill.scss';

interface fullCheckProps {
  check: boolean;
  disabled:boolean;
  id:string;
  name:string;
}

export const FUllPill: React.FC <fullCheckProps> = ({check,disabled,id,name}) => {
  const [checking, setChecking] = useState(check);
  const [disableds, setDisa] = useState(disabled);
  const [ids, setid] = useState(id);
  const [names, setnames] = useState(name);
  useEffect(() => {
    setChecking(check)
  },[check])
  useEffect(() => {
    setDisa(disabled)
  },[disabled])
  useEffect(() => {
    setid(id)
  },[id])
  useEffect(() => {
    setnames(name)
  },[name])
  return(
    <div className="swicth-tab">
        <p>No</p>
        <label className="switch">
          {disableds?<>
          
            <input id={ids} name={names} disabled onClick={()=>{setChecking(!checking)}} type="checkbox"  className={`checkbox ${checking?'checked':''}`} />
          </>:<>
          <input id={ids} name={names}  onClick={()=>{setChecking(!checking)}} type="checkbox"  className={`checkbox ${checking?'checked':''}`} />      
          </>}
          <span className="slider round"></span>
        </label>
        <p>Si</p>
    </div>
  )
}