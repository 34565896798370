import {FormKnowYouMobile} from "./FormKnowYouMobile";
import {FormCreditSimulatorMobile} from "./FormCreditSimulatorMobile";
import {FormKnowYou} from "./FormKnowYou";
import {FormCreditSimulator} from "./FormCreditSimulator";
import {useRequestContext} from '../../../hooks/useRequestContext';
import {Desktop, LandscapePhonesAndSmaller, LandscapeTablet, TabletAndMinis} from "../../../utils/breakpoints";
import "./request-info.scss";

interface FinancialInformationContainerProps {
    typeIdentification?: string,
    identification?: string,
    costProduct?: string,
    nameProduct?: string,
    errors?: any,
    values?: any,
}

export function RequestInfoContainer(props: FinancialInformationContainerProps) {
    const {errors, values} = props;
    const {request, setRequest} = useRequestContext();
    return (
        <>
            <Desktop>
                <div className="flex-container-simulator">
                    <div className="flex-item-left-simulator">
                        <img className="d-none d-xl-block" style={{height:"500px",width:"500px"}} src={request.imagemoto} alt={''}/>
                    </div>
                    <div className="flex-item-right-simulator">
                        <FormKnowYou errors={errors} values={values}/>
                        <FormCreditSimulator errors={errors} values={values}/>
                    </div>
                </div>
            </Desktop>
            <LandscapeTablet>
                <>
                    <FormKnowYou errors={errors} values={values}/>
                    <FormCreditSimulator errors={errors} values={values}/>
                </>
            </LandscapeTablet>
            <LandscapePhonesAndSmaller>
                <>
                    <FormKnowYouMobile errors={errors} values={values}/>
                    <FormCreditSimulatorMobile errors={errors} values={values}/>
                </>
            </LandscapePhonesAndSmaller>
        </>
    )
}