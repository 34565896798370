import React, { useEffect, useState } from 'react';
import { NewHeader } from '../../components/shared/Header';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import './ns.scss';
import { Input, Select } from '../../components/shared/InputSelet';
import {AppRoutes} from "../../Router";
import {Form, Formik} from 'formik';
import * as Yup from "yup";
import { AdminService, AuthService, WLOCreditAPIService } from '../../services';
import { ANIPersona } from '../../context/RequestContext';
import toast from 'react-hot-toast';



export const NewUser = () => {
  const [profiles, setProfiles] = useState([]) as any;
  const [identificacion, setidentificacion] = useState([]) as any;
  const [cargo, setcargo] = useState([]) as any;
  const [oficina, setOficina] = useState([]) as any;

  useEffect(()=>{
    getAlldata()
  },[])


  const getAlldata=async ()=>{
    const profiles= await AuthService.getPerfiles();
    if (profiles.payload.data.Array_Perfiles.Datos_Perfiles.length> 0) {    
      let Perfiles= profiles.payload.data.Array_Perfiles.Datos_Perfiles.map((item:any, key:any) => {
        return ({
            option:item.Pws_Tip_perfil,
            name:item.Pws_Nom_perf
          })
      })
      setProfiles(Perfiles)  
    }

    const tipoIdentificacion= await AdminService.gettipoidentificacion();
    if (tipoIdentificacion.payload.data.LstTiposIdent.Datos_TiposIdentificacion.length> 0) {    
      let identificacion= tipoIdentificacion.payload.data.LstTiposIdent.Datos_TiposIdentificacion.map((item:any, key:any) => {
        return ({
            option:item.s_codigo, 
            name:item.s_descripcion
          })
      })
      setidentificacion(identificacion)  
    }

    const cargo= await AdminService.getCargos();
    if (cargo.payload.data.ListCargos.Datos_Cargos.length> 0) {    
      let cargos= cargo.payload.data.ListCargos.Datos_Cargos.map((item:any, key:any) => {
        return ({
            option:item.s_codigo,
            name:item.s_descripcion
          })
      })
      setcargo(cargos)  
    } 
    
    const oficina= await AdminService.getOficinas();
    if (oficina.payload.data.ListOficina.Datos_Oficina.length> 0) {    
      let oficinas= oficina.payload.data.ListOficina.Datos_Oficina.map((item:any, key:any) => {
        return ({
            option:item.s_codigo,
            name:item.s_nombre
          })
      })
      setOficina(oficinas)  
    } 
  }


  const crearUsuario =async (values: any) => {
    // Crea persona en ANI
    const personANI = await WLOCreditAPIService.consultaSolicitudANI({
      Pws_Tip_Identificacion: values.Pws_Tip_identificacion!,
      Pws_Identificacion: values.Pws_identificacion!
    });
    const pANI = personANI as ANIPersona;
    const fecha = personANI.fechaExpedicion.split('/');

    // Crea usuario en WLO
    const personaRequest = {
      Pws_Identificacion: values.Pws_identificacion,
      Pws_Tip_Identificacion: values.Pws_Tip_identificacion,
      Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
      Pws_Tip_Perfil: values.Pws_Tip_perfil,
      Pws_Tip_person: "N",
      Pws_Tip_ocupa: values.Pws_Tip_ocupa,
      Pws_Nombres: pANI.primerNombre+" "+pANI.segundoNombre,
      Pws_Apellidos1: pANI.primerApellido,
      Pws_Apellidos2: pANI.segundoApellido,
      Pws_Telefono1: values.Pws_telefono,
      Pws_Telefono2: "",
      Pws_Correo: values.Pws_email,
      Pws_Fec_expe: fecha[2]+'-'+fecha[1]+'-'+fecha[0],
      Pws_Lugar_exp: pANI.municipioExpedicion,
      Pws_Fec_nacime: "",
      Pws_Estado_Civil: "",
      Pws_Direccion_res: "",
      Pws_Departamento: "",
      Pws_Ciudad_res: "",
      Pws_Genero: "",
      Pws_Estrato_per: "",
      Pws_Per_cargo: "",
      Pws_Tip_vivienda: "",
      Pws_Niv_estudio: "",
      Pws_Nom_empre: "",
      Pws_fec_ingemp: "",
      Pws_Fideliza: "",
      Pws_Tip_contra: "",
      Pws_Ant_labo: "",
      Pws_Car_emp: "",
      Pws_Nom_Jefedi: "",
      Pws_Direc_emp: "",
      Pws_Ciud_emp: "",
      Pws_tel_emp1: "",
      Pws_tel_emp2: "",
    }
    const result = await WLOCreditAPIService.addSolicitudPersona(personaRequest);

    // Crea usuario en Cygnus
    const cygnus=await WLOCreditAPIService.grabar_cignus({
        Pws_Identificacion: values.Pws_identificacion!,
        Pws_Tip_Identificacion:values.Pws_Tip_identificacion!,
        Pws_Tip_Perfil:values.Pws_Tip_perfil!
    })

    // Crea el usuario en la BD
    if(Number(cygnus.payload.result)>0){
      const createUser=await AuthService.createLogin({
        Pws_Identificacion: values.Pws_identificacion!,
        Pws_Tip_identificacion: values.Pws_Tip_identificacion!,
        Pws_Tip_perfil: values.Pws_Tip_perfil!,
        Pws_Tip_cargo: values.Pws_Tip_cargo,
        Pws_clave_enc1: values.Pws_clave2,
        Pws_clave_enc2: values.Pws_clave2,
        Pws_Cod_oficina: values.Pws_Tip_oficina
        ,
      })
      toast(createUser.payload.message)
    }else{
        toast.error(cygnus.payload.MensajeError);
    }
  }


  

const validationSchema = Yup.object().shape({
        Pws_Tip_perfil: Yup.string().required('(*)'),
        Pws_Tip_oficina: Yup.string().required('(*)'),
        Pws_Tip_identificacion: Yup.string().required('(*)'),
        Pws_identificacion: Yup.string().required('(*)'),
        Pws_email: Yup.string().email("Correo electronico Invalido").required('(*)'),
        Pws_telefono: Yup.number().min(8).required('(*)'),
        Pws_Tip_cargo: Yup.string().required('(*)'),
        Pws_clave: Yup.string().required('(*)').min(8, "Minimo 8 caracteres").required('(*)'),
        Pws_clave2: Yup.string().required('(*)').oneOf([Yup.ref('Pws_clave'),null],"La contraseña no coincide")
})
  return (
    <>
      <ResponsiveAppBar/>
      <Formik
        initialValues={{
          Pws_Tip_perfil: '',
          Pws_Tip_oficina: '',
          Pws_Tip_identificacion: '',
          Pws_identificacion: '',
          Pws_email: '',
          Pws_telefono: '',
          Pws_Tip_cargo: '',
          Pws_clave: '',
          Pws_clave2: ''
        }}
        validationSchema={validationSchema}
        onSubmit={crearUsuario}
      >
        {({errors, isSubmitting}) =>{   
          return (
            <>
              <Form>
                <NewHeader next={`block`} backReturn={AppRoutes.USUARIOS_PLATAFORM}/>
                        
                <div className='New-User'>
                  <div className="left-side">
                    <h4 className='redhead'>Nuevo usuario</h4>
                    <p className='subHead'>Ingresa los datos del nuevo usuario y verifica la información</p>

                    <ul>
                      <Select dir={`goDown`} label={`Perfil del usuario: `} name={`Pws_Tip_perfil`} options={profiles} errors={errors} />
                      <Select dir={`goDown`} label={`Código oficina: `} name={`Pws_Tip_oficina`} options={oficina}  errors={errors}/>
                    </ul>
                    <ul>
                      <Select dir={`goDown`} label={`Tipo de identificación`} name={`Pws_Tip_identificacion`} options={identificacion}  errors={errors}/>
                      <Input type={`text`} dir={`goDown`} label={`Identificación`} name={`Pws_identificacion`} placeholder={`Identificación`}errors={errors} />
                    </ul>
                    <ul>
                    <Input type={`text`} dir={`goDown`} label={`Correo`} name={`Pws_email`} placeholder={`Correo`}errors={errors} />
                      <Input type={`text`} dir={`goDown`} label={`Telefono`} name={`Pws_telefono`} placeholder={`Telefono`}errors={errors} />
                    </ul>
                    <ul>
                      <Select dir={`goDown`} label={`Cargo`} name={`Pws_Tip_cargo`} options={cargo}  errors={errors}/>
                    </ul>
                    <ul>
                      <Input type={`password`} dir={`goDown`} label={`Contraseña`} name={`Pws_clave`} placeholder={`Contraseña`} errors={errors} />
                      <Input type={`password`} dir={`goDown`} label={`Repetir contraseña`} name={`Pws_clave2`} placeholder={`Repetir contraseña`} errors={errors}/>
                    </ul>
                  </div>
                  <div className="right-side">
                    <img src="img/Hombre.png" alt="Hombre" />
                  </div>
                </div>
              </Form>
            </>
          )                   
        }}
      </Formik>
    </>
  )
}
