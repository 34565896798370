import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {DocumentDemo} from "../../assets/icons";
import {useRequestContext} from "../../hooks/useRequestContext";
import { useSignContext } from "../../hooks/useSignContext";
import {WLOCreditAPIService} from "../../services";
import {EnterCode} from "./Modals/EnterCode";
import { Document,pdfjs,Page  } from 'react-pdf'
import Moment from 'moment';
import ClockLoader from "react-spinners/ClockLoader";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function PDFView() {
    
    const {request, setRequest} = useRequestContext();
    const [token, setToken] = useState<string>('')
    const [documento, setDocumento] = useState<string>('')
    const intl = useIntl();
    const {sign, setSign} = useSignContext();
    const [modalShow, setModalShow] = React.useState(false);
    const [numPages, setNumPages] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const onDocumentLoadSuccess = (numPagess:any) => {
        setNumPages(numPagess._pdfInfo.numPages)
    };
    const handleSendOtp = () => {
        const searchSolicitud = {
            Pws_Identificacion: sign.numIdentification,
            Pws_Tip_Identificacion: sign.typeIdentification,
        };

        (async () => {
            const solicitud = await WLOCreditAPIService.consultaSolicitudCygnus(searchSolicitud);
        })();


        const dataDefault = {
            Pws_Num_solicitud:request.numSol!, 
            Pws_Identificacion: sign.numIdentification,
            Pws_Tip_Identificacion: sign.typeIdentification,
            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
            Pws_estado_resotp: "1",
            Pws_Tip_val: "1",
            email: sign.email,
            Pws_phone: sign.phoneNumber,
        };
        (async () => {
            const regOTP = await WLOCreditAPIService.sendOTP(dataDefault);
            const {payload} = regOTP;
            const {token: tokenValue} = payload;
            setToken(tokenValue);

            if (regOTP.payload.result > 0) {
                const getOTP = await WLOCreditAPIService.consultaSolicitudCygnus(searchSolicitud);
                const solicitud = await WLOCreditAPIService.consultaSolicitudCygnus(searchSolicitud);
            }
        })();
        setModalShow(true);
    }
    
    useEffect( () => {
        setIsLoading(true)
        if(!documento){
            WLOCreditAPIService.getDocumento({ Pws_num_solicitud: request.numSol, Pws_Identificacion: request.personCygnus?.Pws_Identificacion,Pws_Tip_Identificacion: request.personCygnus?.Pws_Tip_Identificacion,otp:""}).then((valor)=>{
                setDocumento(valor.payload)
                setIsLoading(false)
             })
        }
    }, [documento, setDocumento])
    
    // const handleDownload = () => {
    //     console.log("handleDownload called");
    //     const link = document.createElement("a");
    //     link.href = documento;
    //     link.download = "Formato Conocimiento Sagrilaft.pdf";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }
    // const handleButtonClick = () => {
    //     console.log("handleButtonClick called");
    //     handleSendOtp();
    //     handleDownload();
    // };

    //   // Agregamos el siguiente bloque de código
    //   useEffect(() => {
    //     const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    //         event.preventDefault();
    //         event.returnValue = 'Se perderá el progreso actual si sales de esta página.';
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    return (

        <div className="row py-0 px-4 gx-0">
            <ClockLoader id='spinner' color={"#d72222"} loading={isLoading} size={100}/>
            <div className="row p-0 m-0">
                <br/>
                <h1 className="title-red">{'VISUALIZACIÓN DEL DOCUMENTO A FIRMAR'}</h1>
                <br/>
                <br/>
                <br/>
                <br/>
                {!documento?<>
                    <DocumentDemo />
                </>:<>
                
                <div className="all-page-container">
                <Document
                file={documento} 
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
                >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={'page_${index + 1}'} pageNumber={index + 1} />
                ))}
                </Document>
                </div>
                </>}    
                <br/>
                <br/>
                <div className="col">
                    <br/><br/><br/>
                    <div className={"form-know-you-item-button"} onClick={handleSendOtp}>
                    {'Firmar Documento'}
                    </div>
                </div>
                <EnterCode
                    token={token}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    sendCode={() => handleSendOtp()}
                />
            </div>
        </div>
    )
}