// import {Switch} from "@mui/material";
// import {Field} from "formik";
// // import { Field, useFormikContext } from "formik";
// import { useEffect, useState } from "react";
// import {useIntl} from "react-intl";
// import {Col, FormGroup, Label, Row} from "reactstrap";
// import { AdminService } from "../../../services";
// import './applicant-info.scss';
// import React from "react";

// interface AplicantOptionsProps {
//     errors?: any;
//     values?: any;
// }
// export function AplicantOptions(props: AplicantOptionsProps) {
    
//     const {errors,values} = props;
//     const intl = useIntl();
//     const [one] =useState(false)
//     const [allOcupaciones, SetAllOcupaciones] = useState([]);


//     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         if (event.target.checked == false){
//             values.Pws_Fideliza="0"
//         }else{
//             values.Pws_Fideliza="1"
//         }
//     };
    
//     useEffect(()=>{
//         getAlldata()
//     },[one])
//     const getAlldata=async ()=>{
//         const ocupaciones= await AdminService.getOcupaciones();
//         if(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones.length>0){
//             SetAllOcupaciones(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones)
//         }
//     }
//     return (
//         <div>
//             <Row className="container-border-app-options">
//                 <Col xl={"3"} lg={"3"} sm={"12"} md={"4"} xs={"12"}>
//                     <section>
//                         <Label className="label-form display-flex" for="Pws_Tip_person">
//                             {intl.formatMessage({id: "who_accredits_motorcycle"})}
//                         </Label>
//                         <br/>
//                         <div className="display-flex">
//                             <Field as="select"
//                                    className={`${errors.Pws_Tip_person ? 'form-know-you-item-input-smaller-2 display-flex input-error' : 'form-know-you-item-input-smaller-2 display-flex'}`}
//                                    name="Pws_Tip_person">
//                                 <option value={""}>{'Seleccione...'}</option>
//                                 <option value={'N'}>{'Natural'}</option>
//                                 {/* <option value={'J'}>{'Juridico'}</option> */}
//                             </Field>
//                         </div>
//                     </section>
//                 </Col>
//                 <Col xl={"3"} lg={"3"} sm={"6"} md={"4"} xs={"12"}>
//                     <FormGroup>
//                         <Label className="label-form display-flex" for="Pws_Tip_ocupa">
//                             {intl.formatMessage({id: "placeholder_occupation_type"})}
//                         </Label>
//                         <br/>
//                         <div className="display-flex">
//                             <Field as="select"
//                                    className={`${errors.Pws_Tip_ocupa ? 'form-know-you-item-input-smaller-2 display-flex input-error' : 'form-know-you-item-input-smaller-2 display-flex'}`}
//                                    name="Pws_Tip_ocupa">
//                                 <option value={""}>{'Seleccione...'}</option>
//                                 {allOcupaciones && allOcupaciones.map((item:any, key) => {
//                                     return (<option value={item.s_codigo}>{item.s_descripcion}</option>)
//                                 })}                               
//                             </Field>
//                         </div>
//                     </FormGroup>
//                 </Col>
//                 <Col xl={"3"} lg={"2"} sm={"6"} md={"2"}>
//                     <FormGroup>
//                         <Label className="label-form display-flex" for="Pws_Fideliza">
//                             {intl.formatMessage({id: "loyalty"})}
//                         </Label>
//                         <br/>
//                         <div className="col-3 toggle-app">
//                             {intl.formatMessage({id: "button_no"})}
//                             <Switch 
//                                 name="Pws_Fideliza"  
//                                 type="checkbox"
//                                 // checked={values.Pws_Fideliza === "1"}
//                                 // onChange={(e) => {
//                                 //     const newValue = e.target.checked ? "1" : "0";
//                                 //     handleChange({
//                                 //         target: {
//                                 //             name: "Pws_Fideliza",
//                                 //             value: newValue,
//                                 //         },
//                                 //     });
//                                 // }}
//                                 value="Pws_Fideliza"
//                                 onChange={handleChange}
//                             />
//                             {intl.formatMessage({id: "button_yes"})}
//                         </div>
//                     </FormGroup>
//                 </Col>
//                 <Col xl={"1"} lg={"4"}/>
//             </Row>
//         </div>
//     )
// }


import { Switch } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { AdminService } from "../../../services";
import './applicant-info.scss';
import React from "react";

// Define los tipos para values y errors
interface AplicantOptionsProps {
    values: {
        Pws_Fideliza?: string;
        Pws_Tip_person?: string;
        Pws_Tip_ocupa?: string;
    };
    errors: {
        Pws_Fideliza?: string;
        Pws_Tip_person?: string;
        Pws_Tip_ocupa?: string;
    };
}

export function AplicantOptions(props: AplicantOptionsProps) {
    const { values, errors } = props;
    const intl = useIntl();
    const [one] = useState(false);
    const [allOcupaciones, SetAllOcupaciones] = useState([]);

    const { handleChange, setFieldValue } = useFormikContext<any>();

    useEffect(() => {
        getAlldata();
    }, [one]);

    const getAlldata = async () => {
        const ocupaciones = await AdminService.getOcupaciones();
        if (ocupaciones.payload.data.ListOcup.Datos_Ocupaciones.length > 0) {
            SetAllOcupaciones(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones);
        }
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked ? "1" : "0";
        setFieldValue("Pws_Fideliza", newValue);
    };

    return (
        <div>
            <Row className="container-border-app-options">
                <Col xl={"3"} lg={"3"} sm={"12"} md={"4"} xs={"12"}>
                    <section>
                        <Label className="label-form display-flex" for="Pws_Tip_person">
                            {intl.formatMessage({ id: "who_accredits_motorcycle" })}
                        </Label>
                        <br />
                        <div className="display-flex">
                            <Field
                                as="select"
                                className={`${errors.Pws_Tip_person ? 'form-know-you-item-input-smaller-2 display-flex input-error' : 'form-know-you-item-input-smaller-2 display-flex'}`}
                                name="Pws_Tip_person"
                            >
                                <option value={""}>{'Seleccione...'}</option>
                                <option value={'N'}>{'Natural'}</option>
                            </Field>
                        </div>
                    </section>
                </Col>
                <Col xl={"3"} lg={"3"} sm={"6"} md={"4"} xs={"12"}>
                    <FormGroup>
                        <Label className="label-form display-flex" for="Pws_Tip_ocupa">
                            {intl.formatMessage({ id: "placeholder_occupation_type" })}
                        </Label>
                        <br />
                        <div className="display-flex">
                            <Field
                                as="select"
                                className={`${errors.Pws_Tip_ocupa ? 'form-know-you-item-input-smaller-2 display-flex input-error' : 'form-know-you-item-input-smaller-2 display-flex'}`}
                                name="Pws_Tip_ocupa"
                            >
                                <option value={""}>{'Seleccione...'}</option>
                                {allOcupaciones && allOcupaciones.map((item: any, key) => {
                                    return (<option key={key} value={item.s_codigo}>{item.s_descripcion}</option>);
                                })}
                            </Field>
                        </div>
                    </FormGroup>
                </Col>
                <Col xl={"3"} lg={"2"} sm={"6"} md={"2"}>
                    <FormGroup>
                        <Label className="label-form display-flex" for="Pws_Fideliza">
                            {intl.formatMessage({ id: "loyalty" })}
                        </Label>
                        <br />
                        <div className="col-3 toggle-app">
                            {intl.formatMessage({ id: "button_no" })}
                            <Switch
                                name="Pws_Fideliza"
                                type="checkbox"
                                checked={values.Pws_Fideliza === "1"}
                                onChange={handleSwitchChange}
                            />
                            {intl.formatMessage({ id: "button_yes" })}
                        </div>
                    </FormGroup>
                </Col>
                <Col xl={"1"} lg={"4"} />
            </Row>
        </div>
    );
}
