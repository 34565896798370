import React from 'react';
import { Input } from '../../InputSelet';
import './ef.scss';
import {Field, Form, Formik} from 'formik';

export const EditInfo = () => {
  
  const handleSubmit =async (values: any) => {
    console.log(values)
}
  return (
    <Formik
                    initialValues={{
                      Pws_Tip_perfil: '',
                            Pws_Tip_oficina: '',
                            Pws_Tip_identificacion: '',
                            Pws_identificacion: '',
                            Pws_Tip_cargo: '',
                            Pws_clave: '',
                            Pws_clave2: ''
                    }}
                    onSubmit={handleSubmit}
                >
                    {({errors, isSubmitting}) =>{
                      
                      return (
                        <>
                            <Form>
    <div className="editInfo info">
        <div className="image">
            <img src="img/modelo-1.png" alt="Moto" />
            <div className="subtitle">foto de categoria</div>
            <button>Cambiar foto</button>
        </div>
        <div className="input">
          
            <Input dir={`goDown`} type={`text`} label={`Nombre del categoría`} name={`category_nombre`} placeholder={`Motos deportivas`} errors={errors}/>
            <div className="submit">
                <button>Guardar cambios</button>
            </div>
        </div>
    </div>
       
    </Form>
        </>
                    )
                    
}
                    
                    }
                </Formik>
  )
}
