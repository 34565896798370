import React from 'react';
import { useFormik } from 'formik';
import './ChangePassword.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import {AppRoutes} from "../../Router";
import {ArrowBack} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {useNavigate} from 'react-router-dom';
import { useLoginContext } from '../../hooks/useLoginContext';
import { useRequestContext } from '../../hooks/useRequestContext';
import { AuthService } from '../../services';
import toast from 'react-hot-toast';
import { WloCreditoService } from '../../types';

export const ChangePassword = () => {
    const navigate = useNavigate();
    const {user, setUser} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const formik = useFormik({
    initialValues: {
        current: '',
        new: '',
        confirm: ''
    },
    onSubmit: async values => {
        const datosUser = sessionStorage.getItem('cambioAdm') ? JSON.parse(sessionStorage.getItem('cambioAdm')!) : "" ;
        const userLogin: WloCreditoService.LoginRequest = {
            Pws_Identificacion:  datosUser.iden  || user.idUser!,
            Pws_Tip_identificacion: datosUser.tipoIden || user.typeUse!,
            Pws_clave_usu: values.current,
            Pws_Tip_perfil: datosUser.tipoPer || user.idProfile!,
            Pws_Tip_Canal: "1",     // Valor constante que refiere al TIPO_CANAL
            Pws_Tip_cambio: "1",    // Valor constante que refiere al ACCESO_USUARIO
        }
        const result = await AuthService.login(userLogin);        
        if (result.payload.Ws_LoginResult.Resultado <= 0) {
            toast.error("contraseña actual no es correcta")
        } else {
            if(values.new==values.confirm){
                const valorChagePass= await AuthService.changepassword({
                    Pws_Tip_cambio: "2",
                    Pws_Identificacion: datosUser.iden  || user.idUser!,
                    Pws_Tip_identificacion: datosUser.tipoIden || user.typeUse!,
                    Pws_Tip_perfil: datosUser.tipoPer || user.idProfile!,
                    Pws_clave_enc1: values.new,
                    Pws_clave_enc2: values.new,
                    Pws_Base_datos: "2"
                })                
                if(Number(valorChagePass.payload.result)>0){
                    toast.success("Contraseña Grabada")
        
                }else{
                    toast.error(valorChagePass.payload.message)
                }
            }else{
                toast.error("Nueva contraseñas no se iguales")
            }
        }
    },
    });
    return (
    <>
        <ResponsiveAppBar/>
        <div className="change-pass">
            <div style={{width:"100%"}}>
        <Fab variant="extended" color="neutral" aria-label="add"
                        onClick={()=>{
                            if(sessionStorage.getItem('cambioAdm')){
                                navigate(AppRoutes.USUARIOS_PLATAFORM, {replace: true});
                                sessionStorage.removeItem('cambioAdm')
                            }else{
                                navigate(AppRoutes.MENU_USER, {replace: true});
                            }
                        }} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {'Atrás'}
                    </Fab>

            </div>
            <div className="header">
                <h1>Cambio de contraseña</h1>
                <h5>La nueva contaseña debe tener al menos una mayúscula, una minúscula, un número, un carácter especial y un mínimo de 8 caracteres.</h5>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <ul>
                    <label htmlFor="current">Contraseña actual</label>
                    <input
                        id="current"
                        name="current"
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.current}
                        placeholder="Ingresar"
                        />
                </ul>
                <ul>
                    <label htmlFor="new">Contraseña nueva</label>
                    <input
                        id="new"
                        name="new"
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.new}
                        placeholder="Ingresar"
                    />
                </ul>
                <ul>
                    <label htmlFor="confirm">Confirmar contraseña</label>
                    <input
                        id="confirm"
                        name="confirm"
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.confirm}
                        placeholder="Ingresar"
                    />
                </ul>
                <div className="password-change">
                    <button type="submit">Restablecer</button>
                </div>
            </form>
        </div>
    </>
    );
};