import React from 'react';
import {FUllPill} from '../Pill/Pill';
import {Select, Select2} from '../SolicDocument/SolicDocument';
import './ci.scss';

export const BoxConfirmInfo = () => {
  const Options2 = [
    {
        option: 'Option1'
    },
    {
        option: 'OPtion2'
    },
    {
        option: 'Option3'
    },
    {
        option: 'Option4'
    }
  ] 
    return(
        <div className="box">
            <div className="sub-box redBox">
                <h3>Applicant Information</h3>
                <ul>
                    <li>
                        <label>Names</label>
                        <p>Luis Fernando</p>
                    </li>
                    <li>
                        <label>Surname</label>
                        <p>Sanchez arias</p>
                    </li>
                    <li>
                        <label>Type of identification</label>
                        <p>C.C.</p>
                    </li>
                    <li>
                        <label>Identification</label>
                        <p>1030465345</p>
                    </li>
                    <li>
                        <label>Date of issue</label>
                        <p>DD/MM/AAAA</p>
                    </li>
                    <li>
                        <label>Place of issue</label>
                        <p>Bogotá</p>
                    </li>
                    <li>
                      <label htmlFor="Date_Of_Birth">Date of birth</label>
                      <p> <input type="text" name="Date_Of_Birth" placeholder="DD/MM/AAAA" /> </p>
                    </li>
                    <li>
                      <label>Marital status</label>
                      <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                </ul>
                <ul>
                    <li>
                      <label htmlFor="Residence_Adress">Residence Adress</label>
                      <p> <input type="text" name="Residence_Adress" placeholder="DD/MM/AAAA" /> </p>
                    </li>
                    <li>
                      <label>Department</label>
                      <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                      <label>City</label>
                      <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                        <label>Phone 1</label>
                        <p>3176543445</p>
                    </li>
                    <li>
                        <label>Phone 1</label>
                        <p>3176543445</p>
                    </li>
                    <li>
                        <label>Email</label>
                        <p>ejemplo@gmail.com</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Genre</label>
                        <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                        <label>Social stratum</label>
                        <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                        <label>N° of dependents</label>
                        <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                        <label>Type of housing </label>
                        <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                        <label>Level of education</label>
                        <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                </ul>
            </div>
            <div className="sub-box redBox">
                <h3>Applicant's activity</h3>
                <ul>
                    <li>
                      <label htmlFor="Company_name">Company name</label>
                      <p> <input type="text" name="Company_name" placeholder="Company Name" /> </p>
                    </li>
                    <li>
                      <label>Date of entry</label>
                      <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                      <label>Date of entry</label>
                      <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                      <label>Date of entry</label>
                      <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                      <label htmlFor="Position_held">Type of contract</label>
                      <p><input type="text" name="Position_held" placeholder="Psoition Held"/></p>
                    </li>
                    <li>
                      <label htmlFor="Jefe_directo">Length of service</label>
                      <p><input type="text" name="Jefe_directo" placeholder="Jefe directo"/></p>
                    </li>
                </ul>
                <ul>
                    <li>
                      <label htmlFor="Address_of_the_company">Address of the company</label>
                      <p> <input type="text" name="Address_of_the_company" placeholder="Address of the company" /> </p>
                    </li>
                    <li>
                      <label>City</label>
                      <p><Select2 Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} /></p>
                    </li>
                    <li>
                      <label htmlFor="Phone_1">Phone 1</label>
                      <p> <input type="text" name="Phone_1" placeholder="Phone 1" /> </p>
                    </li>
                    <li>
                      <label htmlFor="Phone_2">Phone 2</label>
                      <p> <input type="text" name="Phone_2" placeholder="Phone 2" /> </p>
                    </li>
                </ul>
            </div>
            {/* <div className="submit">
                <button>Revisado</button>
            </div> */}
        </div>
    );
}


export const ConfirmInfo = () => {
  const Options1 = [
    {
        option: 'Option1'
    },
    {
        option: 'OPtion2'
    },
    {
        option: 'Option3'
    },
    {
        option: 'Option4'
    }
  ] 
  const Options2 = [
    {
        option: 'Option1'
    },
    {
        option: 'OPtion2'
    },
    {
        option: 'Option3'
    },
    {
        option: 'Option4'
    }
  ] 
  return (
    <div className="detailCredit detailInfo" >
        <div className="header">
            <h2 className="title">Now let's confirm your information</h2>
            <p className="presenta">Please fill in the data</p>
        </div>
        <div className="selectOp">
          <ul>
            <label>Who accredits the motorcycle?</label>
            <Select Options={Options1} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} />
          </ul>
          <ul>
            <label>occupation type</label>
            <Select Options={Options2} bg={`#fff`} clr={`#4A4A4A`} str={`#4A4A4A`} />
          </ul>
          <ul>
              <FUllPill check={false} disabled={false} id={"checkox"} name={"checkox"}/>
          </ul>
        </div>
        <div className="body">
            <div className="mainDetail">
                <BoxConfirmInfo/>
            </div>
        </div>
    </div>
  )
}
