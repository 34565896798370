import React, {useRef, useState} from 'react';
import './ud.scss';
import {ArrowBack} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {Search, ArrowDown, UserIcon, UserAddIcon} from '../../../assets/icons/component';
import {Table} from '../Table/Table';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from "../../../Router";
import { PerfilFunc } from '../PerfilFunc';

const userHeaders = [
    {
        header: 'Empleado'
    },
    {
        header: 'Área'
    },
    {
        header: 'Permisos'
    },
    {
        header: 'Acciones'
    }
]

const userData = [
    {
        item1: 'Johnny Hawkins',
        item2: 'Soporte',
        item3: 'Completos',
        color1: '#017D73',
    },
    {
        item1: 'Jane Cooper',
        item2: 'Créditos',
        item3: 'Edición',
        color1: '#F5A700',
    },
    {
        item1: 'Stella Williamson',
        item2: 'Soporte',
        item3: 'Visualizar',
        color1: '#BD271E',
    },
    {
        item1: 'Johnny Hawkins',
        item2: 'Soporte',
        item3: 'Completos',
        color1: '#017D73',
    },
    {
        item1: 'Jane Cooper',
        item2: 'Créditos',
        item3: 'Edición',
        color1: '#F5A700',
    },
    {
        item1: 'Stella Williamson',
        item2: 'Soporte',
        item3: 'Visualizar',
        color1: '#BD271E',
    },
    {
        item1: 'Johnny Hawkins',
        item2: 'Soporte',
        item3: 'Completos',
        color1: '#017D73',
    },
    {
        item1: 'Jane Cooper',
        item2: 'Créditos',
        item3: 'Edición',
        color1: '#F5A700',
    },
    {
        item1: 'Stella Williamson',
        item2: 'Soporte',
        item3: 'Visualizar',
        color1: '#BD271E',
    },
    {
        item1: 'Johnny Hawkins',
        item2: 'Soporte',
        item3: 'Completos',
        color1: '#017D73',
    },
    {
        item1: 'Jane Cooper',
        item2: 'Créditos',
        item3: 'Edición',
        color1: '#F5A700',
    },
    {
        item1: 'Stella Williamson',
        item2: 'Soporte',
        item3: 'Visualizar',
        color1: '#BD271E',
    },
]

interface InfoProps {
    info: {
        item1: string,
        item2: string,
        item3: string,
        color1: string,
    };
}


export const UserItem: React.FC <InfoProps> = ({info}) => {
    return (
      <>
          <ul>
              <li className="listItem firstItem">{info.item1}</li>
              <li className="listItem">{info.item2}</li>
              <li className="listItem"><span style={{background: info.color1}}></span>{info.item3}</li>
              <li className="listItem" style={{display: 'flex', alignItems: 'flex-start'}}><button style={{color: '#fff', padding: '4px 10px'}}>Ver detalle</button></li>
          </ul>
      </>
    )
}

const Options = [
    {
        option: 'Código'
    },
    {
        option: 'Tipo de perfil'
    },
    {
        option: 'Descripción'
    },
    {
        option: 'Tipo de Perfil'
    }
]

interface OptionProps {
    Options: {
        option: string
    }[];
    bg: string;
    clr: string;
    str: string;
}

export const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <li className='filter'>
            <ArrowDown stroke={str} />
            <select name="filter" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </li>
    )
}
export const Select2: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <>
            <ArrowDown stroke={str} />
            <select name="search" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </>
    )
}

export const AdminDoc: React.FC = () => {
    const navigate = useNavigate();
    const textInputRef = useRef<HTMLInputElement>(null);
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        let enteredText = textInputRef.current!.value;
        console.log(enteredText);
    }
    const handleSubmitBack = () => {
        navigate(AppRoutes.MENU_USER,{replace:true});
    };
    const mewUser = () => {
        navigate(AppRoutes.NEW_USER,{replace:true});
    };
    const [perfunc, setPerfunc] = useState(false)
  return (
    <>
    {
        perfunc
        ?<PerfilFunc setPerfunc={setPerfunc}/>
        :<></>
    }
    <div className="UserDoc adminDoc">
        <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                      ATRAS
                    </Fab>
        <div className="UserDocument">
            <h4>Administración de perfiles</h4>
            <div className="UserTable">
                <div className="first-part only">
                    <div className="userAdd">
                        <button onClick={()=>setPerfunc(true)} style={{background: '#D0021B', color: '#fff', width: '20%'}}>Agregar nuevo perfil <UserIcon/></button>
                        <button onClick={mewUser} style={{background: '#4A4A4A', color: '#fff', width: '20%'}}>Filtrar resultados <UserAddIcon/></button>
                        <div className="search">
                            <form action="" method="post" onSubmit={handleSubmit}>
                                <li>
                                    <Search />
                                    <input type="text" name="seacrh" placeholder="Search" ref={textInputRef}/>
                                </li>
                            </form>
                        </div>
                    </div>
                    <div className="table">
                        <Table TableItem={UserItem} headers={userHeaders} data={userData}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
