import axios from "axios";

export class InventoryService {

    //Categorias 
    static async postCategoryQuery(catalogos:{
        pws_tip_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/categoryQuery`,catalogos);
            return data;
        } catch (e) {

            throw e;
        }
    }
    static async postCategoryUpdate(catalogos:{
        pws_tip_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/categoryUpdate`,catalogos);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postCategoryInsert(catalogos:{
        pws_tip_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/categoryInsert`,catalogos);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postCategoryDelete(catalogos:{
        pws_tip_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/categoryDelete`,catalogos);
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    //Productos 
    static async postProductQuery(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/productQuery`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postProductUpdate(Product:{
        pws_cod_prod:string,
        pws_c_descrip:string,
        pws_precio_uni:string,
        pws_precio_comer:string,
        pws_stock:string,
        pws_imagen:string,
        pws_cod_cate:string,
        pws_tipo_prod:string,
        pws_atr_tip_productos:string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/productUpdate`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postProductInsert(Product:{
        pws_c_descrip:string,
        pws_precio_uni:string,
        pws_precio_comer:string,
        pws_stock:string,
        pws_imagen:string,
        pws_cod_cate:string,
        pws_tipo_prod:string,
        pws_atr_tip_productos:string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/productInsert`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postProductDelete(Product:{
        pws_cod_prod:string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/productDelete`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }

    //Atributos
    static async postAttributesQuery(Product:{
        pws_cod_atr:string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/attributesQuery`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postAttributesUpdate(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/attributesUpdate`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postAttributesInsert(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/attributesInsert`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postAttributesDelete(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/attributesDelete`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }

    //Tipos de producto
    static async postTipProductQuery(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/tipProductQuery`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postTipProductUpdate(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/tipProductUpdate`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postTipProductInsert(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/tipProductInsert`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async postTipProductDelete(Product:{
        pws_cod_producto:string,
        pws_tip_producto:string,
        pws_cate_producto:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+`/inventory/tipProductDelete`,Product);
            return data;
        } catch (e) {
            throw e;
        }
    }

}


