import { Navigate, Outlet } from "react-router-dom";
import {useLoginContext} from '../../hooks/useLoginContext';

export const ProtectedRoute = ({ user, redirectPath = '/profile-options' }: any) => {
  const { setUser} = useLoginContext();
  const items = JSON.parse(localStorage.getItem('User')!);
  
  if (!user.idProfile) {
    if(!items){
      return <Navigate to={redirectPath} replace />;
    }else{
      if(!items.idProfile){
        return <Navigate to={redirectPath} replace />;
  
      }else{
        if(items.idProfile=="4"){
          setUser(items)
          return <Navigate to={"menu-agent"} replace />;
        }else if(items.idProfile==="2"){
          setUser(items)
          return <Navigate to={"catalog"} replace />;
  
        }else if(items.idProfile==="1"){
          setUser(items)
          return <Navigate to={"menu-agent"} replace />;
  
        }else if(items.idProfile==="3"){
          setUser(items)
          return <Navigate to={"menu-agent"} replace />;
  
        }
      }
      
    }
  }
  
  
    return <Outlet />;
};
  