import React, {useState,useEffect} from 'react';
import './table.scss';
import './pagination.scss';
import { VscChevronLeft, VscChevronRight, VscChevronDown } from "react-icons/vsc";

export const Table = ({TableItem, headers, data}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [tableItems, setTableItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentItems, setCurrentItems] = useState([]);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [ipp, setIpp] = useState(false);
    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            const res = data ;
            setTableItems(res);
            setLoading(false);
        }
        fetchData();
    },[data]);

    useEffect(()=>{
        if(tableItems !== undefined){
            setCurrentItems(tableItems.slice(indexOfFirstItem,indexOfLastItem));
        }
    },[tableItems, indexOfFirstItem, indexOfLastItem]);
    const lastPage = Math.floor(Object.values(tableItems).length/itemsPerPage) + 1;
    const paginate = (pageNumber) => {
        if(typeof pageNumber === "number") {
            setCurrentPage(pageNumber);
        }else{
            console.log(pageNumber);
        }
    }
    const nextPaginate = (pageNumber) => {
        if(pageNumber===lastPage){
            setCurrentPage(pageNumber);
        }else{
            setCurrentPage(pageNumber+1);
        }
    }
    const backPaginate = (pageNumber) => {
        if(pageNumber===1){
            setCurrentPage(pageNumber);
        }else{
            setCurrentPage(pageNumber-1);
        }
    }
    const pageNumbers = [];
    if(tableItems!==undefined){
        for (let i = 1; i <= Math.ceil(tableItems.length / itemsPerPage); i++ ){
            pageNumbers.push(i);
        }
    }

    let tempNumberOfPages = [...pageNumbers];

    let dotsInitial = '...';
    let dotsLeft = '... ';
    let dotsRight = ' ...';
    let dotsEnd = ' ... ';

    if(pageNumbers.length === 4){
        tempNumberOfPages = [1,2,3,4];
    }else if(pageNumbers.length === 5){
        tempNumberOfPages = [1,2,3,4,5];
    }else if(currentPage>=1 && currentPage<3 && pageNumbers.length > 5){
        tempNumberOfPages = [1,2,3, dotsInitial, pageNumbers.length];
    }else if(currentPage === 3 && pageNumbers.length > 5){
        tempNumberOfPages = [1, dotsInitial, currentPage-1, currentPage, currentPage+1, dotsLeft, pageNumbers.length];
    }else if(currentPage > 3 && currentPage < pageNumbers.length-2 && pageNumbers.length > 5){
        const sliced1 = pageNumbers.slice(currentPage-2, currentPage);
        const sliced2 = pageNumbers.slice(currentPage, currentPage+1);
        tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight , pageNumbers.length];
    }else if(currentPage > pageNumbers.length - 3 && pageNumbers.length > 5) {
        const sliced = pageNumbers.slice(pageNumbers.length -4);
        tempNumberOfPages = [1,2, dotsEnd, ...sliced];
    }else if(pageNumbers.length === 0){
        tempNumberOfPages = [];
    }

    const sliderRestrictionBack = () => {
        if(currentPage === 1){
            return 'disable';
        }
    }
    const sliderRestrictionNext = () => {
        if(currentPage === lastPage){
            return 'disable';
        }
    };
    const activePaginate = (pageNumber) => {
        if(currentPage === pageNumber){
            return "active";
        }
    }
    useEffect(()=>{
        document.body.addEventListener('click',(event)=>{
            if(event){
                if(event.path){
                    if(event.path[0].tagName !== 'svg'){
                        setIpp(false);
                    }
                }
            }
        
        
        })
    })
    if(loading){
        return <h1>Loading...</h1>
    }

    return (
    <div className="usertable">         
        <div className="responsiveFix">
            <div className="responsiveAuto">
                <ul>
                    {
                        headers.map((item, i)=>{
                            return(
                                <li key={i} className='listHeader listItem'>{item.header}</li>
                            )
                        })
                    }
                </ul>
                {
                    currentItems.map((info, i) => {
                        return(
                            <TableItem key={i} info={info} />
                        )
                    })
                }
            </div>
        </div>
        <div className="pagination">
            <ul className="itemsPerPage">
                <p>Rows for page:</p>
                <ul>
                    <p style={{margin: "0px"}} >{itemsPerPage}</p>
                    <ul className={ipp?`open`:`close`}>
                        <li><p onClick={()=>setItemsPerPage(5)}>5</p></li>
                        <li><p onClick={()=>setItemsPerPage(10)}>10</p></li>
                        <li><p onClick={()=>setItemsPerPage(15)}>15</p></li>
                    </ul>
                </ul>
                <span onClick={()=>{setIpp(!ipp);}} style={{cursor:'pointer', marginTop: '0px'}}><VscChevronDown /></span>
            </ul>
            <ul className="pageNum">
                <li onClick={()=>backPaginate(currentPage)} className={`paginate-item back ${sliderRestrictionBack()}`}><VscChevronLeft /> </li>
                {
                    tempNumberOfPages.map((number,i)=>{
                        return(
                            <li onClick={()=> paginate(number)} key={i} className={`paginate-item paginate-number ${activePaginate(number)}`}>{number}</li>
                        )
                    })
                }
                <li onClick={()=>nextPaginate(currentPage)} className={`paginate-item next ${sliderRestrictionNext()}`}><VscChevronRight /> </li>
            </ul>
        </div>
    </div>
    )
}
export const TableF = ({TableItem, headers, data, setFunction}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [tableItems, setTableItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentItems, setCurrentItems] = useState([]);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [ipp, setIpp] = useState(false);
    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            const res = data ;
            setTableItems(res);
            setLoading(false);
            }
            fetchData();
            setCurrentPage(1);
        }, [data]);
    useEffect(()=>{
        if(tableItems !== undefined){
            for(let i = 0; i < tableItems.length; i++){
                    tableItems[i].R_s_cod_prod = data[i].R_s_cod_prod
            }
            setCurrentItems(tableItems.slice(indexOfFirstItem,indexOfLastItem));
        }
    },[tableItems, indexOfFirstItem, indexOfLastItem]);
    const lastPage = Math.floor(Object.values(tableItems).length/itemsPerPage) + 1;
    const paginate = (pageNumber) => {
        if(typeof pageNumber === "number") {
            setCurrentPage(pageNumber);
        }else{
            console.log(pageNumber);
        }
        };
    const nextPaginate = (pageNumber) => {
        if(pageNumber===lastPage){
            setCurrentPage(pageNumber);
        }else{
            setCurrentPage(pageNumber+1);
        }
    }
    const backPaginate = (pageNumber) => {
        if(pageNumber===1){
            setCurrentPage(pageNumber);
        }else{
            setCurrentPage(pageNumber-1);
        }
    }
  const pageNumbers = [];
  if(tableItems!==undefined){
       for (let i = 1; i <= Math.ceil(tableItems.length / itemsPerPage); i++ ){
            pageNumbers.push(i);
        }
    }

    let tempNumberOfPages = [...pageNumbers];

    let dotsInitial = '...';
    let dotsLeft = '... ';
    let dotsRight = ' ...';
    let dotsEnd = ' ... ';

    if(pageNumbers.length === 4){
        tempNumberOfPages = [1,2,3,4];
    }else if(pageNumbers.length === 5){
        tempNumberOfPages = [1,2,3,4,5];
    }else if(currentPage>=1 && currentPage<3 && pageNumbers.length > 5){
        tempNumberOfPages = [1,2,3, dotsInitial, pageNumbers.length];
    }else if(currentPage === 3 && pageNumbers.length > 5){
        tempNumberOfPages = [1, dotsInitial, currentPage-1, currentPage, currentPage+1, dotsLeft, pageNumbers.length];
    }else if(currentPage > 3 && currentPage < pageNumbers.length-2 && pageNumbers.length > 5){
        const sliced1 = pageNumbers.slice(currentPage-2, currentPage);
        const sliced2 = pageNumbers.slice(currentPage, currentPage+1);
        tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight , pageNumbers.length];
    }else if(currentPage > pageNumbers.length - 3 && pageNumbers.length > 5) {
        const sliced = pageNumbers.slice(pageNumbers.length -4);
        tempNumberOfPages = [1,2, dotsEnd, ...sliced];
    }else if(pageNumbers.length === 0){
        tempNumberOfPages = [];
    }

  const sliderRestrictionBack = () => {
      if(currentPage === 1){
        return 'disable';
      }
  }
  const sliderRestrictionNext = () => {
      if(currentPage === lastPage){
        return 'disable';
      }
  };
  const activePaginate = (pageNumber) => {
      if(currentPage === pageNumber){
          return "active";
      }
  }
  useEffect(()=>{
    document.body.addEventListener('click',(event)=>{
        if(event){
            if(event.path){
                if(event.path[0].tagName !== 'svg'){
                    setIpp(false);
                }
            }
        }
    })
  })
  if(loading){
      return <h1>Loading...</h1>
  }

  return (
    <div className="usertable">         
        <div className="responsiveFix">
            <div className="responsiveAuto">
                <ul>
                    {
                        headers.map((item, i)=>{
                            return(
                                <li key={i} className='listHeader listItem'>{item.header}</li>
                            )
                        })
                    }
                </ul>
                {
                    currentItems.map((info, i) => {
                        return(
                            <TableItem key={i} info={info} setFunction={setFunction}/>
                        )
                    })
                }
            </div>
        </div>
        <div className="pagination">
            <ul className="itemsPerPage">
                <p>Rows for page:</p>
                <ul>
                    <p style={{margin: "0px"}} >{itemsPerPage}</p>
                    <ul className={ipp?`open`:`close`}>
                        <li><p onClick={()=>setItemsPerPage(5)}>5</p></li>
                        <li><p onClick={()=>setItemsPerPage(10)}>10</p></li>
                        <li><p onClick={()=>setItemsPerPage(15)}>15</p></li>
                    </ul>
                </ul>
                <span onClick={()=>{setIpp(!ipp);}} style={{cursor:'pointer', marginTop: '0px'}}><VscChevronDown /></span>
            </ul>
            <ul className="pageNum">
                <li onClick={()=>backPaginate(currentPage)} className={`paginate-item back ${sliderRestrictionBack()}`}><VscChevronLeft /> </li>
                {
                    tempNumberOfPages.map((number,i)=>{
                        return(
                            <li onClick={()=> paginate(number)} key={i} className={`paginate-item paginate-number ${activePaginate(number)}`}>{number}</li>
                        )
                    })
                }
                <li onClick={()=>nextPaginate(currentPage)} className={`paginate-item next ${sliderRestrictionNext()}`}><VscChevronRight /> </li>
            </ul>
        </div>
    </div>
  )
}