import React, { useState } from 'react';
import * as Yup from "yup";
import { CoInfo } from '../../components/shared/CoInfo/CoInfo';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import Direccion from '../../components/shared/modal/Direccion';

export const CodebtInfo = () => {
  const [popup, setPopup] = useState("close");
  return (
    <>
     {
            popup === "open"
            ?<>
                <Direccion setPopup={setPopup}/>
            </>
            :<></>
         }
    <div>
      <ResponsiveAppBar />
      <CoInfo setPopup={setPopup}/>
    </div>
    </>
  )
};
