import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Checkcircle } from '../../assets/icons/component';
import { MenuBar } from '../../components';
import { Popup } from '../../components/shared/Popup';
import './cambio.scss';
import {Row} from 'reactstrap';
import * as Yup from "yup";
import {typeIdentification} from '../../__mocks__/data';
import {Field, Form, Formik} from 'formik';
import { AuthService, WLOCreditAPIService } from '../../services';
import toast from 'react-hot-toast';
import {useLoginContext} from '../../hooks/useLoginContext';

interface CambioProp {
    setfunc: Dispatch<SetStateAction<boolean>>,
    correo:string
}

const CambioPop : React.FC <CambioProp> = ({setfunc,correo}) => {
    return (
        <>
            <div className="cambioPop">
                <Checkcircle />
                <div>
                    <p>Tu clave ha sido enviada al</p>
                    <p>correo suministrado : {correo}</p>
                </div>
                <button onClick={()=>setfunc(false)}>ok</button>
            </div>
        </>
    )
}

export const Cambio = () => {
    const [pop, setPop] = useState(false);
    const [CorreoEnviado, setCorreo] = useState("");
    const {user} = useLoginContext();
    useEffect(() => {
        document.body.classList.add('body-cambio');
        return () => {
            document.body.classList.remove('body-cambio');
        };
    }, []);
    
    const handleSubmit =async (values: any) => {
        
        const personaRequest = {
            Pws_Identificacion: values.Pws_Identificacion,
            Pws_Tip_Identificacion: values.Pws_Tip_Identificacion
        }
        // const userEmail = await getEmailFromUser(values.Pws_Tip_Identificacion, values.Pws_Identificacion);
        const valorRetorno = await WLOCreditAPIService.consultaSolicitudCygnus(personaRequest)
        if(Number(valorRetorno.payload.result)>0){
                if(valorRetorno.payload.data.datos_persona.Datos_persona.length>0){
                    if(valorRetorno.payload.data.datos_persona.Datos_persona[0] == user.idProfile){

                        let ChangePassword = {
                            Pws_Tip_cambio: '1',
                            Pws_Identificacion: values.Pws_Identificacion,
                            Pws_Tip_identificacion: values.Pws_Tip_Identificacion,
                            Pws_Tip_perfil: user.idProfile!,
                            Pws_Base_datos: '2',
                            Pws_Correo:valorRetorno.payload.data.datos_persona.Datos_persona[0].Pws_Correo
                        }
                        setCorreo(valorRetorno.payload.data.datos_persona.Datos_persona[0].Pws_Correo)

                        const valorcambiosPassword =  await AuthService.changepassword(ChangePassword)
                        if(Number(valorcambiosPassword.payload.result)>0){
                            setPop(true)
                        }else{
                            toast.error(valorcambiosPassword.payload.message)
                        }
                    }else{
                        toast.error("No es el mismo perfil")

                    }
                   
                }else{
            toast.error("No se Encontro usuario")
                }
        }else{
            toast.error("Ocurrio Un Error en la Busqueda")
        }
    }
    const validationSchema = Yup.object().shape({
        Pws_Tip_Identificacion: Yup.string()
            .required('(*)'),
        Pws_Identificacion: Yup.string().required('(*)')
    })
  return (
    <div className="cambio-parrent">  
        {
          pop
          ?<Popup setfunc={setPop} Body={<CambioPop setfunc={setPop} correo={CorreoEnviado}/>} />
          :<></>
        }
        <MenuBar />
        <div className='cambio'>
            <div className="body">
                <div className="head">
                    <p className="title">¿OlvidASTE TU  contraseña?</p>
                    <p className="subTitle">Por favor ingresa tu correo electrónico</p>
                </div>
                <Formik
                    initialValues={{
                        Pws_Tip_Identificacion: '',
                        Pws_Identificacion: ''
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({errors, isSubmitting}) => (
                        <>
                            <Form>
                                <Row>
                                    <div className="field">
                                        <Field 
                                               as="select"
                                               name="Pws_Tip_Identificacion">
                                            <option>{errors.Pws_Tip_Identificacion ? 'Tipo de Identificacion' : 'Documento'}</option>
                                            {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {
                                                return (
                                                    <option key={key}
                                                            value={item.s_codigo}>{item.s_descripcion}</option>
                                                )
                                            })}
                                        </Field>
                                        <br/><br/>
                                        <Field
                                            id="Pws_Identificacion"
                                            name="Pws_Identificacion"
                                            type="text"
                                            placeholder={errors.Pws_Identificacion ? 'Es obligatorio' : 'Numero'}
                                        />
                                        </div>
                                </Row>
                                
                                <div className="submit">
                                    <button type="submit" >Restablecer</button>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    </div>
  )
}