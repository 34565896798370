import React from 'react';
import './upl.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import {UserDocument} from '../../components/shared/UserDocument/UserDocument';

export const UsersPlatform = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <UserDocument />
    </>
  )
}
