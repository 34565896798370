import React from 'react';
import { NewHeader } from '../../components/shared/Header';
import { Select } from '../../components/shared/InputSelet';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import { Switch } from '../../components/shared/Switch';
import {Field, Form, Formik} from 'formik';
import './ud.scss';

export const UserDetails = () => {
  const options = [
    {
      option: 'Vendedor',
    },
    {
      option: 'Vendedor1',
    },
    {
      option: 'Vendedor2',
    },
    {
      option: 'Vendedor3',
    },
  ]
  const handleSubmit =async (values: any) => {
    console.log(values)
}
  return (
    <>
        <ResponsiveAppBar/>
        <Formik
                    initialValues={{
                      Pws_Tip_perfil: '',
                            Pws_Tip_oficina: '',
                            Pws_Tip_identificacion: '',
                            Pws_identificacion: '',
                            Pws_Tip_cargo: '',
                            Pws_clave: '',
                            Pws_clave2: ''
                    }}
                    onSubmit={handleSubmit}
                >
                    {({errors, isSubmitting}) =>{
                      
                      return (
                        <>
                            <Form>
        <NewHeader next={`none`} backReturn={''}/>
        <div className="user-detail">
            <div className="first-part">
                <h4 className="redhead">Detalle del usuario</h4>
                <div className="detail">
                  <ul className="perfil">
                    <Select dir={`goSide`} label={`Perfil del usuario: `} name={`Perfil`} options={options}  errors={[]}/>
                  </ul>
                  <ul>
                    <li>
                      <h4>Nombre:</h4>
                      <p>Primero Segundo Apellido</p>
                    </li>
                    <li>
                      <h4>Identificación</h4>
                      <p>1030546789</p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <h4>Email:</h4>
                      <p>Analista1@cliente.com</p>
                    </li>
                    <Switch check={true} text={`Desbloquear Usuario`} />
                  </ul>
                  <ul>
                    <li>
                      <h4>Celular:</h4>
                      <p>3213453466</p>
                    </li>
                    <Switch check={true} text={`Bloquear Usuario`} />
                  </ul>
                  <ul>
                    <li>
                      <h4>Cargo:</h4>
                      <p>Vendedor</p>
                    </li>
                  </ul>
                </div>
            </div>
            <div className="second-part">
                <img src="img/user.png" alt="User" />
            </div>
        </div>
        </Form>
        </>
                    )
                    
}
                    
                    }
                </Formik>
    </>
  )
}
