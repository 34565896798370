import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import './new.scss';
export const New = () => {
  useEffect(() => {
    document.body.classList.add('body-login');
    return () => {
        document.body.classList.remove('body-login');
    };
  }, []);
  const Links1 = [
    {
      link: '/profile-options',
      text: 'profile-options'
    },
    {
      link: '/catalog',
      text: 'catalog'
    },
    {
      link: '/sign',
      text: 'sign'
    },
    {
      link: '/home_view',
      text: 'home'
    },
    {
      link: '/login_view',
      text: 'login'
    },
    {
      link: '/menu_agent_view',
      text: 'menu-agent'
    },
    {
      link: '/view_document_view',
      text: 'view_document'
    },
    {
      link: '/view_document_sign_view',
      text: 'view_document_sign'
    },
    {
      link: '/confirmation_view',
      text: 'confirmation'
    },
    {
      link: '/Change-password',
      text: 'Change-password'
    },
    {
      link: '/*',
      text: 'Error Page'
    }
  ]
  const Links2 = [
    {
      link: '/loginfirma17',
      text: 'loginfirma17'
    },
    {
      link: '/Referencias-y-documentos-13',
      text: 'Referencias-y-documentos-13'
    },
    {
      link: '/Informacion-financiera-10',
      text: 'Informacion-financiera-10'
    },
    {
      link: '/Solicitudes-de-credito_view',
      text: 'Solicitudes-de-credito'
    },
    {
      link: '/Detalle-del-credito_view',
      text: 'Detalle-del-credito'
    },
    {
      link: '/Detalle-del-codeudor-requerido_view',
      text: 'Detalle-del-codeudor-requerido'
    },
    {
      link: '/sargilat_view',
      text: 'Mensaje-despues-de-validar-sargilat'
    },
    {
      link: '/Confirm-information',
      text: 'Confirm-information'
    },
    {
      link: '/CoDebt-information_view',
      text: 'CoDebt-information'
    },
    {
      link: '/new-pdf',
      text: 'New PDF Page'
    },
  ]
  const Links3 = [
    {
      link: '/Users_Platform_view',
      text: 'Users-Platform'
    },
    {
      link: '/Users_Platform_view2',
      text: 'Users-Platform2'
    },
    {
      link: '/New_User_view',
      text: 'New-User'
    },
    {
      link: '/Perfil_Detail_view',
      text: 'Perfil-Detail'
    },
    {
      link: '/Datos_view',
      text: 'Datos'
    },
    {
      link: '/Adminstartive_Catalog_view',
      text: 'Adminstartive-Catalog-view'
    },
    {
      link: '/information-solicitud',
      text: 'Information-solicitud'
    },
    {
      link: '/Detalle_credito_view',
      text: 'Detalle-credito'
    },
    {
      link: '/Cambio_view',
      text: 'Cambio-view'
    },
    {
      link: '/UserDetails_view',
      text: 'UserDetails-view'
    },
    {
      link: '/OTP_view',
      text: 'OTP-view'
    },
    {
      link: '/New_PDF2_view',
      text: 'New_PDF2-view'
    },
    {
      link: '/Detail_Category_view',
      text: 'Detail_Category-view'
    },
    {
      link: '/Detail_Category_view2',
      text: 'Detail_Category-view2'
    },
    {
      link: '/Components_view',
      text: 'Components-view'
    },
    {
      link: '/Admin_perfil_view',
      text: 'Admin_perfil-view'
    },
  ]
  
  return (
    <div className="new" >
      <header>
        <h1>Sprint 1 - All Pages</h1>
      </header>
      <ol>
        {
          Links1.map((item,i)=>{
            return <Link key={i} to={item.link} ><li>{item.text}</li></Link>
          })
        }
      </ol>
      <header>
        <h1>Sprint 2 - All Pages</h1>
      </header>
      <ol>
        {
          Links2.map((item,i)=>{
            return <Link key={i} to={item.link} ><li>{item.text}</li></Link>
          })
        }
      </ol>
      <header>
        <h1>Sprint 3 - All Pages</h1>
      </header>
      <ol>
        {
          Links3.map((item,i)=>{
            return <Link key={i} to={item.link} ><li>{item.text}</li></Link>
          })
        }
      </ol>
    </div>
  )
}
