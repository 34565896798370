import React from 'react';
import './dc.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import { CategoryDetail } from '../../components/shared/DetailCatComp/CategoryDetail';

export const DetailCategoryView = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <CategoryDetail />
    </>
  )
}
