import React from 'react';
import { Delete, Edit } from '../../../../assets/icons/component';
import './addEdit.scss';

const Info = [
    {
        info1: '1',
        info2: 'Color',
        color1: 'red',
        color2: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        color1: 'red',
        color2: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        color1: 'red',
        color2: '',
    },
    {
        info1: '1',
        info2: 'Color',
        color1: 'red',
        color2: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        color1: 'red',
        color2: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        color1: 'red',
        color2: '',
    },
    {
        info1: '1',
        info2: 'Color',
        color1: 'red',
        color2: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        color1: 'red',
        color2: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        color1: 'red',
        color2: '',
    },
    {
        info1: '1',
        info2: 'Color',
        color1: 'red',
        color2: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        color1: 'red',
        color2: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        color1: 'red',
        color2: '',
    },
    {
        info1: '1',
        info2: 'Color',
        color1: 'red',
        color2: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        color1: 'red',
        color2: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        color1: 'red',
        color2: '',
    },
    {
        info1: '1',
        info2: 'Color',
        color1: 'red',
        color2: '',
    },
    {
        info1: '2',
        info2: 'Cilindraje',
        color1: 'red',
        color2: '',
    },
    {
        info1: '3',
        info2: 'Módelo',
        color1: 'red',
        color2: '',
    },
]

interface ItemProp {
    item : {
        info1: string,
        info2: string,
        color1: string,
        color2: string,
    };
}

export const Item : React.FC <ItemProp> = ({item}) => {
    return (
        <ul>
            <li className={`${item.color1}`}>{item.info1}</li>
            <li className={`${item.color2}`}>{item.info2}</li>
            <li style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}> <Edit /> <Delete /></li>
        </ul>
    );
}

export const Attribute = () => {
  return (
    <div className="editInfo attribute">
        <div className="info">
            <div className="attributos">
                <h4>Parametrización atributos</h4>
                <div className="table">
                    <div className="edit">
                        <span style={{cursor: 'pointer'}}><Edit /></span>
                    </div>
                    <div className="table-body">
                        <div className="body">
                        <ul>
                            <li className={`red`}>Código</li>
                            <li className={`red`}>Atributo</li>
                            <li className={`red`}>Acciones</li>
                        </ul>
                            {
                                Info.map((item, i)=>{
                                    return (
                                        <Item item={item} key={i} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="submit">
                    <button style={{background: '#D0021B', color: '#fff', padding: '5px 10px', borderRadius: '9px'}}>+</button>
                    <button style={{background: '#4a4a4a', color: '#fff', padding: '5px 10px', borderRadius: '9px'}}>Guardar cambios</button>
                </div>
            </div>
        </div>
    </div>
  )
}
