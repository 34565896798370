import {ErrorMessage, Field} from "formik";
import {useIntl} from "react-intl";
import {cities, typeContract} from "../../../__mocks__/data";
import './applicant-info.scss';
import ParseNumberSmaller from "../../shared/Utilities/ParseNumberSmaller";
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal,useEffect , useState } from "react";
import _ from "lodash";
import { AdminService } from "../../../services";
import toast from "react-hot-toast";


interface FormPersonalInfoActivityProps {
    errors?: any;
    values?: any;
}

export function FormPersonalInfoActivity(props: FormPersonalInfoActivityProps) {
    const {errors,values} = props;
    const intl = useIntl();
    const [one] = useState(null);
    const [allCities, setAllCiudades] = useState(cities as any);
    const [ciudades, setCiudades] = useState(cities as any);
    const [TipoContrato, setAllTipoContrato] = useState([] as any);
    useEffect(()=>{
        allData()
        AdminService.getAllCiudades().then((Datos:any)=>{
            setAllCiudades(Datos.payload.data)
        })
    },[one])
    
    const allData=async ()=>{
        const tipocon= await AdminService.getTipoContrato();
     if(tipocon.payload.data.listTipoContr.Datos_TipoContrato.length>0 && 
        parseInt(tipocon.payload.data.listTipoContr.Datos_TipoContrato[0].s_codigo)>0){
            setAllTipoContrato(tipocon.payload.data.listTipoContr.Datos_TipoContrato)
        }else{
            let tipContrato = [
                {s_codigo: "1", s_descripcion: "INDEFINIDO"},
                {s_codigo: "2", s_descripcion: "TERMINO FIJO"},
                {s_codigo: "3", s_descripcion: "N/A"},
                {s_codigo: "4", s_descripcion: "OBRA O LABOR"},
                {s_codigo: "5", s_descripcion: "TEMPORAL"}
            ]
            setAllTipoContrato(tipContrato)
        }
    }
    useEffect(()=>{
       if(allCities){
        
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'});
        setCiudades(citiesDepent)
       }
    },[allCities])
    const cambios=(evento:any)=>{
        var dateStart= new Date((evento.target.value).replace("-", "/"));
        var dateEnd=new Date();
        if(dateStart.getTime()>dateEnd.getTime()){
            toast("La fecha de ingreso no debe ser mayor a la fecha actual")
        }else{           
            var results = dateEnd.getFullYear() - dateStart.getFullYear()
            let diferencia = dateEnd.getTime() - dateStart.getTime();
            let diasDeDiferencia = diferencia / 1000 / 60 / 60 / 24;
            if(diasDeDiferencia>365 && dateStart.getMonth() <= dateEnd.getMonth() && dateStart.getDate() <= dateEnd.getDate() )
            {
                values.Pws_Ant_labo =results

            }else{
                values.Pws_Ant_labo = results-1
            }
        }
    }
    return (
        <div className="row container-border-app">
               <div className="row" >
                <div className="text-left">
                    
                <div className="col" style={{"textAlign":"left"}}>
                     <h3 >Actividad del solicitante</h3>

                </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_company_name"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_Nom_empre"}
                               name={"Pws_Nom_empre"}
                               className={`${errors.Pws_Nom_empre ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               placeholder={intl.formatMessage({id: "placeholder_company_name"})}/>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_date_of_admission"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_fec_ingemp"}
                               name={"Pws_fec_ingemp"}
                               type={"date"}
                               onBlur={cambios}
                               placeholder={'MM/dd/YYYY'}
                               className={`${errors.Pws_fec_ingemp ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}/>
                        <div className="label-fields-peps-warning">
                            <ErrorMessage name="Pws_fec_ingemp"/>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_type_of_contract"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Tip_contra ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Tip_contra">
                            <option>{intl.formatMessage({id: "placeholder_type_of_contract"})}</option>
                            {TipoContrato && TipoContrato.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_labor_old"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_Ant_labo"}
                               name={"Pws_Ant_labo"}
                               component={ParseNumberSmaller}
                               className={`${errors.Pws_Ant_labo ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               placeholder={intl.formatMessage({id: "placeholder_labor_old"})}/>
                        <div className="label-fields-peps-warning">
                            <ErrorMessage name="Pws_Ant_labo"/>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_job_that_performs"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_Car_emp"}
                               name={"Pws_Car_emp"}
                               className={`${errors.Pws_Car_emp ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               placeholder={intl.formatMessage({id: "placeholder_job_that_performs"})}/>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_direct_boss"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_Nom_Jefedi"}
                               name={"Pws_Nom_Jefedi"}
                               className={`${errors.Pws_Nom_Jefedi ? 'form-know-you-item-input-xxs display-flex input-error' : 'form-know-you-item-input-xxs display-flex'}`}
                               placeholder={intl.formatMessage({id: "placeholder_direct_boss"})}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_company_address"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_Direc_emp"}
                               name={"Pws_Direc_emp"}
                               className={`${errors.Pws_Direc_emp ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               placeholder={intl.formatMessage({id: "placeholder_company_address"})}/>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</div>
                    <div className="display-flex">
                        <Field as="select"
                               className={`${errors.Pws_Ciud_emp ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Ciud_emp"
                        >
                            <option>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</option>
                            {ciudades && ciudades.ListCiudad.Datos_Ciudad.map((item: { s_codigo: string | number | readonly string[] | undefined; s_nombre: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_phone_1"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_tel_emp1"}
                               name={"Pws_tel_emp1"}
                               className={`${errors.Pws_tel_emp1 ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               placeholder={intl.formatMessage({id: "placeholder_phone_1"})}/>
                    </div>
                </div>
                <div className="col">
                    <div
                        className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_phone_2"})}</div>
                    <div className="display-flex">
                        <Field id={"Pws_tel_emp2"}
                               name={"Pws_tel_emp2"}
                               className={`${errors.Pws_tel_emp2 ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               placeholder={intl.formatMessage({id: "placeholder_phone_2"})}/>
                    </div>
                </div>
                <div className="col"/>
                <div className="col"/>
            </div>
        </div>
    )
}