export const Color = {
    primaryColor: '#D0021B',
    secondaryColor: '#004d40',
    tartiaryColor: '#092647',
    fourthColor: '#b8c419',
    bgHeaderColor: '#4A4A4A',
    darkColor: '#4A4A4A',
    bgPrimaryColor: '#FFFFFF',
    bgSecondaryColor: '#4A4A4A',
    bgTartiaryColor: '#303030',
    bgFourthColor: '#e6e6e6',
    bgColorBlack: '#000000',
    textColor: '#303030',
    textColorWhite: '#FFFFFF',
    textColorBlack: '#000000',
    textColorGrey: '#e6e6e6',
    borderColor: '#f2f2f2',
    boxShadowInsetColor: 'rgba(0, 0, 0, 0.12)',
    boxShadowColor: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    overlayColor: 'rgba(0, 0, 0, 0.6)'
};