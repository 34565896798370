import React, { Dispatch, SetStateAction } from 'react';
import { Xross } from "../../../assets/icons/component";
import './popup.scss';

interface PopupProp {
    setfunc: Dispatch<SetStateAction<boolean>>;
    Body?: React.ReactNode;
}

export const Popup : React.FC <PopupProp> = ({setfunc, Body}) => {
  setTimeout(() => {
    const popup = document.querySelector('.realPopUp') as HTMLElement;
    const left = (window.screen.width/2) - (popup?.offsetWidth/2);
    if(popup!==undefined) {
      popup.style.left = left+'px';
    }
  },0);
  return (
    <>
        <div onClick={()=>setfunc(false)} className="backdrop"></div>
        <div className="popup realPopUp" style={{top: `${window.scrollY + 100}px`}}>
            <div className="xross">
                <span style={{cursor: 'pointer'}} onClick={()=>setfunc(false)}><Xross/></span>
            </div>
            {Body}
        </div>
    </>
  )
}
