import {useMediaQuery} from "react-responsive";

// @ts-ignore
export const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 980 })
    return isDesktop ? children : null
}
// @ts-ignore
export const TabletAndMinis = ({ children }) => {
    const isTabletAndMinis = useMediaQuery({ maxWidth: 767 })
    return isTabletAndMinis ? children : null
}

// @ts-ignore
export const LandscapeTablet = ({ children }) => {
    const isLandscapeTablet = useMediaQuery({ minWidth: 768, maxWidth: 979 })
    return isLandscapeTablet ? children : null
}

// @ts-ignore
export const LandscapePhonesAndSmaller = ({ children }) => {
    const isLandscapePhonesAndSmaller = useMediaQuery({ maxWidth: 767 })
    return isLandscapePhonesAndSmaller ? children : null
}
// @ts-ignore
export const LargeDesktop = ({ children }) => {
    const isLargeDesktop = useMediaQuery({ minWidth: 1200 })
    return isLargeDesktop ? children : null
}
