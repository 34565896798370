import '../applicant-info.scss';
import {useIntl} from "react-intl";
import {Modal} from "react-bootstrap";
import {Field} from 'formik';
import toast from 'react-hot-toast';
import {WLOCreditAPIService} from '../../../../services';
import * as _ from 'lodash';
import {typeIdentification} from '../../../../__mocks__/data';
import {useRequestContext} from '../../../../hooks/useRequestContext';
import {useLoginContext} from '../../../../hooks/useLoginContext';
import { useEffect, useState } from 'react';

interface FormSpouseProps {
    show: boolean;
    onHide: () => void;
    values?: any;
    solicitud?: any;
    dataConyuge?:any;
}

export function FormSpouseCodeudor(props: FormSpouseProps) {
    
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    
    const {show, onHide, values, solicitud, dataConyuge} = props;
    
    const [input, setInput] = useState({
        Pws_Con_nomsol: "",
        Pws_Con_Tip_identif: "",
        Pws_Con_identif: "",
        Pws_Con_tel: "",
    });
    const handlerChange = (e:any) =>{
        e.preventDefault();
        let {value, name } = e.target;
        setInput({...input, [name]: value })
    } 
    const intl = useIntl();
    const handleSaveConyugue = async (e: any) => {
        e.preventDefault();
        if (!_.isEmpty(input.Pws_Con_Tip_identif!) || !_.isEmpty(input.Pws_Con_nomsol!) || !_.isEmpty(input.Pws_Con_identif!) || !_.isEmpty(input.Pws_Con_tel!)) {
                const conyugue = {
                    Pws_Num_solicitud: solicitud,
                    Pws_Identificacion: values.Pws_Identificacion,
                    Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                    Pws_Con_Tip_identif: input.Pws_Con_Tip_identif,
                    Pws_Con_nomsol: input.Pws_Con_nomsol,
                    Pws_Con_identif: input.Pws_Con_identif,
                    Pws_Con_tel: input.Pws_Con_tel,
                }
                const result = await WLOCreditAPIService.addConyugue(conyugue)
                if (result.payload.result === "1") {
                    toast.success('Se ha guardado de manera exitosa');
                    onHide();
                }
        }
    }
    useEffect(() => {
        if(dataConyuge && dataConyuge.Pws_Con_identif){
            setInput({...dataConyuge})
        }
        return () => {
            setInput({
                Pws_Con_nomsol: "",
                Pws_Con_Tip_identif: "",
                Pws_Con_identif: "",
                Pws_Con_tel: "",
            })
        }
    }, [dataConyuge])
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="title-modal-spouse">
                        {intl.formatMessage({id: "spouse_data"})}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "full_name_spouse"})}</div>
                            <Field id={"Pws_Con_nomsol"}
                                   name={"Pws_Con_nomsol"}
                                   className={'form-know-you-item-input-mobile display-flex'}
                                    placeholder={intl.formatMessage({id: "enter"})}
                                   value={input?.Pws_Con_nomsol || ''}
                                   onChange={handlerChange} />
                                   </div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_identification_type"})}</div>
                            <Field as="select"
                                   id={"Pws_Con_Tip_identif"}
                                   name={"Pws_Con_Tip_identif"}
                                   className={ 'form-know-you-item-input-mobile display-flex'}
                                   value={input?.Pws_Con_Tip_identif || ''} 
                                   onChange={handlerChange} 
                                >
                                <option>{intl.formatMessage({id: "enter"})}</option>
                                {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key: number) => {
                                    return (
                                        <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                                    )
                                })}
                            </Field>

                        </div>
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "identification_spouse"})}</div>
                            <Field id={"Pws_Con_identif"}
                                   name={"Pws_Con_identif"}
                                   type={"number"}
                                   className={ 'form-know-you-item-input-mobile display-flex'}
                                   placeholder={intl.formatMessage({id: "enter"})}
                                   value={input?.Pws_Con_identif || ''} 
                                   onChange={handlerChange} /></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div
                                className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_phone_1"})}</div>
                            <Field id={"Pws_Con_tel"}
                                   name={"Pws_Con_tel"}
                                   type={"number"}
                                   className={ 'form-know-you-item-input-mobile display-flex'}
                                   placeholder={intl.formatMessage({id: "placeholder_phone_1"})}
                                   value={input?.Pws_Con_tel || ''}
                                   onChange={handlerChange}/>
                            <br/>

                        </div>
                        <div className="col"/>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={"form-know-you-item-button"} onClick={props.onHide}>
                                {intl.formatMessage({id: "button_cancel"})}
                            </div>
                        </div>
                        <div className="col">

                        </div>
                        <div className="col">
                            <div className={"form-know-you-item-button-gray"} onClick={handleSaveConyugue}>
                                {intl.formatMessage({id: "button_continue"})}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}