import './ac.scss';
// import React from 'react';
import React, {useRef} from 'react';
import { useEffect, useState } from 'react';
import {AppRoutes} from "../../Router";
import { InventoryService } from '../../services';
import {NewHeader} from '../../components/shared/Header';
import {Table} from '../../components/shared/Table/TableAdmin';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import {Search,ArrowDown} from '../../assets/icons/component';
import { useNavigate } from 'react-router-dom';

const headers = [
    {header: 'Categoria'},
    {header: 'N° de productos'},
    {header: 'Acciones'}
]

interface OptionProps {
    Options: {
        option: string
    }[];
    bg: string;
    clr: string;
    str: string;
}

interface InfoProps {
    info: {
        R_s_cod_cate: string,
        R_s_nom_cate: string,
        R_s_cant_prod_cate: string,
    };
}

const Options = [
    {option: 'Categoria'},
    {option: 'N° Productos'}
]

export const CatalogoMain: React.FC = () => {
    const [catalog, setCatalogo] = React.useState([] as any);
    const [prueba, setPrueba] = React.useState([] as any);
    const [Producto, setPruebaProducto] = React.useState([] as any);
    const [usuariosFiltro, setUsuariosFiltro] = React.useState([] as any);
    const textInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const gotoCategoria = (idtogo:any) => {
        navigate(AppRoutes.CATEGORIA_VIEW_DETAIL,{replace:true,state:idtogo});
    };

    useEffect(()=>{  
        getListCategory()
    },[]);

    const getListCategory = async ()=>{
        const ListCategoryResponse= await InventoryService.postCategoryQuery({
            pws_tip_producto:"1"
        });
        const ListCategoryResponseTransform = ListCategoryResponse.payload.data.RespDetCat.RespuestaDetCat;
        
        const IndexProducto = [] as any;
        const NombreProducto = [] as any;
        for(let i = 0; i < ListCategoryResponseTransform.length; i++){
            await new Promise(resolve => setTimeout(resolve, 10));
            const InfoProductResponse = await InventoryService.postProductQuery({pws_cod_producto: "",pws_tip_producto: "",pws_cate_producto: ListCategoryResponseTransform[i].R_s_cod_cate});    
            if(InfoProductResponse.payload.data.RespProd != null){
                const InfoProductResponseTransform = InfoProductResponse.payload.data.RespProd.RespuestaProd;
                
                IndexProducto[i] = InfoProductResponseTransform.length;
                
            }else{
                IndexProducto[i] = 0;
            }
            NombreProducto[i] = ListCategoryResponseTransform[i].R_s_nom_cate;
        }
        setPrueba(IndexProducto);
        setPruebaProducto(NombreProducto);
        try {
            if (ListCategoryResponseTransform == null){
                setCatalogo(null);
                setUsuariosFiltro(null);
            }
            else{
                setCatalogo(ListCategoryResponseTransform);
                setUsuariosFiltro(ListCategoryResponseTransform);
            }
        }catch (error){}
        
    }
    const Item: React.FC <InfoProps> = ({info}) => {
        return (
        <>
            <ul>
                <li className="listItem firstItem">{info.R_s_nom_cate}</li>
                <li className="listItem">{info.R_s_cant_prod_cate}</li>
                <li className="listItem" style={{display: 'flex', alignItems: 'flex-start'}}><button  onClick={()=>{gotoCategoria(info)}} style={{color: '#fff', padding: '4px 10px'}}>Ver Detalle</button></li>
            </ul>
        </>
        )
    }

    const [selectedOption, setSelectedOption] = useState('');
    const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {         
        const handleSelectChange = (event : any) => {
            setSelectedOption(event.target.value);
        };
        return(
            <li className='Selector'>
                <ArrowDown stroke={str} />
                <select id="search" value={selectedOption} onChange={handleSelectChange} style={{background:bg,color:clr}} >
                    {
                        Options.map((option,i)=>{
                            return(
                                <option key={i} value={option.option}>{option.option}</option>
                            )
                        })
                    }
                </select>
            </li>
        )
    }

    const handleChange = async (event: any) => {
        const filtro = event.target.value.toString().toUpperCase();
        let filterNumbers = catalog.filter((num:any) => {
            if(selectedOption === "Categoria" || selectedOption === ""){
                if((num.R_s_nom_cate).includes(filtro))
                    return true
                else
                    return false
            }
            else if(selectedOption === "N° Productos"){
                if(num.R_s_cod_cate != null){
                    if((num.R_s_cant_prod_cate.toString()).includes(filtro.toString()))
                        return true
                    else
                        return false
                }
                else
                    return false
            }
            else{
                return true
            }
        });
        setUsuariosFiltro(filterNumbers)
    }

    return (
        <>
            <ResponsiveAppBar/>
            <NewHeader next={`none`} backReturn={AppRoutes.MENU_USER}/>
            <div className="catalog">
                <div className="first-part">
                    <img src="img/corporative.png" alt="corporativa"/>
                </div>
                <div className="second-part">
                    <h4 className="redhead">Administración de catálogo</h4>
                    <div className="fullTable">
                        <div className="contenedor">
                            <div className="izquierda">
                                <Select Options={Options} bg={`#4A4A4A`} clr={`#fff`} str={`#fff`} />
                            </div>
                            <div className="derecha">
                                <li className="Icono">
                                    <Search />
                                </li>
                                <input type="text" name="search" placeholder="Search" ref={textInputRef} onChange={handleChange}/> 
                            </div>    
                        </div>
                        
                        <div className="table">
                            <div>
                                <Table TableItem={Item} headers={headers} data={usuariosFiltro} NProductos={prueba} NameProducto={Producto}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
      )
}