import React, { Dispatch, SetStateAction } from 'react';
import { Xross } from "../../../assets/icons/component";
import { Select2 } from "../InputSelet";
import './pf.scss';
import  { Switch } from '../Switch';

interface PerfilFuncProp {
    setPerfunc: Dispatch<SetStateAction<boolean>> 
}

export const PerfilFunc : React.FC <PerfilFuncProp> = ({setPerfunc}) => {
    const options = [
        {
            option : '1',
        },
        {
            option : '2',
        },
        {
            option : '3',
        }
    ]
  return (
    <>
        <div onClick={()=>setPerfunc(false)} className="backdrop"></div>
        <div className="perfilfun" style={{top: `${window.scrollY + 100}px`}}>
            <div className="xross">
                <span style={{cursor: 'pointer'}} onClick={()=>setPerfunc(false)}><Xross/></span>
            </div>
            <div className="perfhead">
                <ul>
                    <Select2 dir={`goDown`} label={`Rol/ funcionalidad`} name={`Rol`} options={options} errors={[]}/>
                    <Switch check={true} text={`Habilitada`} />
                </ul>
                <ul>
                    <p>Selecciona los permisos que va a tener el perfil en esta funcionalidad</p>
                </ul>
                <ul>
                    <Switch check={false} text={`Ejecución`} />
                    <Switch check={true} text={`Edición`} />
                    <Switch check={true} text={`Consulta`} />
                    <Switch check={false} text={`Visualización`} />
                </ul>
                <ul>
                    <button onClick={()=>setPerfunc(false)} >Guarda cambiosr</button>
                </ul>
            </div>
        </div>
    </>
  )
}
