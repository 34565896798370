import { createContext, useState } from "react";

interface SignProviderProps {
    children: React.ReactNode;
}

type Sign = {
    numSol?: string,
    numIdentification?: string,
    typeIdentification?: string,
    email?: string,
    phoneNumber?: string,
    otp?: string,
    signValidated: boolean,
    identityValidated: boolean,
    ipSign?: string
}


const signDefault: Sign = {signValidated: false, identityValidated: false};
export const SignContext = createContext({sign: signDefault, setSign: (sign: Sign) => {}})

export const SignProvider = ({children}: SignProviderProps) => {
    const [sign, setSign] = useState({signValidated: false, identityValidated: false});
    return (
        <SignContext.Provider value={{sign, setSign}}>
             {children}
        </SignContext.Provider> 
     )
}