import {FormApplicantIncome} from "./FormApplicantIncome";
import {FormApplicantFinancialOperations} from "./FormApplicantFinancialOperations";
import {FormApplicationIncomeMobile} from "./FormApplicationIncomeMobile";
import {FormApplicantFinancialOperationsMobile} from "./FormApplicantFinancialOperationsMobile";
import {Desktop, LandscapePhonesAndSmaller, LandscapeTablet} from "../../../utils/breakpoints";
import { useIntl } from "react-intl";

interface FinancialInfoContainerProps {
    errors?: any;
    values?: any;
}

export function FinancialInfoContainer(props: FinancialInfoContainerProps) {
    const {errors, values} = props;
    const intl = useIntl();
    const renderTitle = () => {
        return (
            <>
                <h1 className="title-red">{intl.formatMessage({id: "form_financial_information_title"})}</h1>
                <h1 className="subtitle-medium">{intl.formatMessage({id: "form_credit_information_subtitle"})}</h1>
                <br />
            </>
        )
    }

    return (
        <>
            <Desktop>
                <div className="row ">
                    {renderTitle()}
                    <div className="col-xl container-border">
                        <FormApplicantIncome errors={errors} values={values}/>
                    </div>
                    <div className="col-xl container-border">
                        <FormApplicantFinancialOperations errors={errors} values={values}/>
                    </div>
                </div>
            </Desktop>
            <LandscapeTablet>
                <div className="row ">
                {renderTitle()}
                    <div className="col-lg container-border">
                        <FormApplicantIncome errors={errors} values={values}/>
                    </div>
                    <div className="col-lg container-border">
                        <FormApplicantFinancialOperations errors={errors} values={values}/>
                    </div>
                </div>
            </LandscapeTablet>
            <LandscapePhonesAndSmaller>
                <>
                {renderTitle()}
                    <FormApplicationIncomeMobile errors={errors} values={values}/>
                    <FormApplicantFinancialOperationsMobile errors={errors} values={values}/>
                </>
            </LandscapePhonesAndSmaller>
        </>
    )
}
