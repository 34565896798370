import { MenuBar, UserProfile } from "../../components";
import './ProfileOptions.scss';

interface ProfileOptionsProps {}
export function ProfileOptions(_props:ProfileOptionsProps) {
    return (
        <>
            <MenuBar/>
            <UserProfile/>
        </>
    )
}