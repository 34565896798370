import { createContext, useState } from "react";

interface LoginProviderProps {
    children: React.ReactNode;
}

export type User = {
    idUser?: string,
    idProfile?: string,
    language?: string,
    lastLoginDate?: string,
    ipLogin?: string,
    istipo?: string,
    typeUse?:string,
    isSuccessfulLogin: boolean
}

const userDefault: User = {
        language: 'es',
        isSuccessfulLogin: false,
        istipo:''
}
export const LoginContext = createContext({user: userDefault, setUser: (user: User) => {}})

export const LoginProvider = ({children}: LoginProviderProps) => {
    const [user, setUser] = useState({
        isSuccessfulLogin: false
    });
    return (
       <LoginContext.Provider value={{user, setUser}}>
            {children}
       </LoginContext.Provider> 
    )
}