import {FormPersonalInfo} from "./FormPersonalInfo";
import {FormPersonalInfoActivity} from "./FormPersonalInfoActivity";
import {FormPersonalInfoMobile} from "./FormPersonalInfoMobile";
import {FormPersonalInfoActivityMobile} from "./FormPersonalInfoActivityMobile";
import {Desktop, LandscapePhonesAndSmaller, LandscapeTablet} from "../../../utils/breakpoints";
import {AplicantOptions} from "./ApplicantOptions";
import { useIntl } from "react-intl";
import { useRequestContext } from "../../../hooks/useRequestContext";
import { useEffect } from "react";

import Moment from 'moment';
interface PersonalInfoContainerProps {
    errors?: any;
    values?: any;
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

export function PersonalInfoContainer(props: PersonalInfoContainerProps) {
    const {errors, values,setPopup} = props;
    const intl = useIntl();
    const {request, setRequest} = useRequestContext();
    useEffect(()=>{
        if(request.personCygnus){
            if(!request.searchANI){
                if(values.Pws_Apellidos1){
                    values.Pws_Ant_labo=request.personCygnus.Pws_Ant_labo!
                    values.Pws_Apellidos1=request.personCygnus.Pws_Apellidos1!
                    values.Pws_Apellidos2=request.personCygnus.Pws_Apellidos2!
                    values.Pws_Car_emp=request.personCygnus.Pws_Car_emp!
                    values.Pws_Ciud_emp=request.personCygnus.Pws_Ciud_emp!
                    values.Pws_Ciudad_res=request.personCygnus.Pws_Ciudad_res!
                    values.Pws_Correo=request.personCygnus.Pws_Correo!
                    values.Pws_Departamento=request.personCygnus.Pws_Departamento!
                    values.Pws_Direc_emp=request.personCygnus.Pws_Direc_emp!
                    values.Pws_Direccion_res=request.personCygnus.Pws_Direccion_res!
                    values.Pws_Estado_Civil=request.personCygnus.Pws_Estado_Civil!
                    values.Pws_Estrato_per=request.personCygnus.Pws_Estrato_per!
                    values.Pws_Fec_expe=Moment((request.personCygnus.Pws_Fec_expe!.includes("-00")?request.personCygnus.Pws_Fec_expe!.split("-00")[0]:request.personCygnus.Pws_Fec_expe!)).format('yyy-MM-DD')
                    values.Pws_Fec_nacime=Moment((request.personCygnus.Pws_Fec_nacime!.includes("-00")?request.personCygnus.Pws_Fec_nacime!.split("-00")[0]:request.personCygnus.Pws_Fec_nacime!)).format('yyy-MM-DD')
                    values.Pws_Genero=request.personCygnus.Pws_Genero! 
                    values.Pws_Identificacion=request.personCygnus.Pws_Identificacion!
                    values.Pws_Lugar_exp=request.personCygnus.Pws_Lugar_exp!
                    values.Pws_Niv_estudio=request.personCygnus.Pws_Niv_estudio!
                    values.Pws_Nom_Jefedi=request.personCygnus.Pws_Nom_Jefedi!
                    values.Pws_Nom_empre=request.personCygnus.Pws_Nom_empre!
                    values.Pws_Nombres=request.personCygnus.Pws_Nombres!
                    values.Pws_Tip_contra=request.personCygnus.Pws_Tip_contra!
                    values.Pws_Per_cargo=request.personCygnus.Pws_Per_cargo!
                    values.Pws_Telefono1=request.personCygnus.Pws_Telefono1!
                    values.Pws_Telefono2=request.personCygnus.Pws_Telefono2!
                    values.Pws_Tip_Estado=request.personCygnus.Pws_Tip_Estado!
                    values.Pws_Tip_Identificacion=request.personCygnus.Pws_Tip_Identificacion!
                    values.Pws_Tip_person=request.personCygnus.Pws_Tip_person!
                    values.Pws_Tip_vivienda=request.personCygnus.Pws_Tip_vivienda!
                    values.Pws_fec_ingemp=Moment(new Date((request.personCygnus.Pws_fec_ingemp!.includes("-00")?request.personCygnus.Pws_fec_ingemp!.split("-00")[0]:request.personCygnus.Pws_fec_ingemp!))).format('yyyy-MM-DD')
                    values.Pws_tel_emp1=request.personCygnus.Pws_tel_emp1!
                    values.Pws_tel_emp2=request.personCygnus.Pws_tel_emp2!
                    values.Pws_Fideliza=request.personCygnus.Pws_Fideliza!
                }else{
                    
                }
               
            }
        }
        if(request.direcciones){
            values.Pws_Direccion_res=request.direcciones.road+" "+request.direcciones.mainno+" "+request.direcciones.suffix+" "+request.direcciones.secondaryno+" "+request.direcciones.suffix2+" "+request.direcciones.complementaryno+" "+request.direcciones.Complement+" "+request.direcciones.complementaryno2+" "+request.direcciones.Complement3+" "+request.direcciones.complementaryno3+" "+request.direcciones.Neighborhood
           
            }
    },[request])
    const renderTitle = () => {
        return (
            <>
                <h1 className="title-red">{intl.formatMessage({id: "form_applicant_information_title"})}</h1>
                <h1 className="subtitle-medium">{intl.formatMessage({id: "form_credit_information_subtitle"})}</h1>
                <br />
            </>
        )
    }

    return (
        <>
            <Desktop>
                {renderTitle()}
                <AplicantOptions values={values} errors={errors}/>
                <FormPersonalInfo values={values} errors={errors} setPopup={setPopup}/>
                <FormPersonalInfoActivity values={values} errors={errors}/>
            </Desktop>
            <LandscapeTablet>
            {renderTitle()}
                <AplicantOptions values={values} errors={errors}/>
                <FormPersonalInfo values={values} errors={errors} setPopup={setPopup}/>
                <FormPersonalInfoActivity values={values} errors={errors}/>
            </LandscapeTablet>
            <LandscapePhonesAndSmaller>
            {renderTitle()}
                <AplicantOptions values={values} errors={errors}/>
                <FormPersonalInfoMobile values={values} errors={errors} setPopup={setPopup}/>
                <FormPersonalInfoActivityMobile values={values} errors={errors}/>
            </LandscapePhonesAndSmaller>
        </>
    )
}