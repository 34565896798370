import React from 'react';
import './sdc.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import {SolicDocument} from '../../components/shared/SolicDocument/SolicDocument';

export const SolicitudesDeCredito = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <SolicDocument />
    </>
  )
}
