import axios from "axios";
import {WloCreditoService} from "../types";

export class AuthService {
    static async getPerfiles(): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/profiles');
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async login(user: WloCreditoService.LoginRequest): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login', user);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async changepassword(changepass: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/changepass', changepass);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async createLogin(CreateLogin: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/createUser', CreateLogin);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async LoginBloqueo(CreateLogin:{
        Pws_Identificacion: string,
        Pws_Tip_identificacion: string,
        Pws_Identificacion_aud: string,
        Pws_Tip_identificacion_aud: string,
        Pws_Fecha_Cam: string,
        Pws_Tip_Accion: string,
        Pws_Observacion: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/loginBloqueo', CreateLogin);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async loginFirma(user: {
        Pws_Correo: string,
        Pws_Telefono: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login-firma-correo', user);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async loginValidacion(user: {
        Pws_Identificacion: string,
        Pws_Tip_identificacion: string,
        Pws_Correo: string,
        Pws_Telefono: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login-firma-identidad', user);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async documentSagrilaft(datoconsul: {
        idDatoConsultado: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/documentoSagrilaft', datoconsul);
            return data;
        } catch (e) {
            throw e;
        }
    }
}