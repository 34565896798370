import './addEdit.scss';
import {Form, Formik} from 'formik';
import toast from 'react-hot-toast';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InventoryService } from '../../../../services';
import {Edit } from '../../../../assets/icons/component';

interface ItemProp {
    item : {
        R_s_cod_atr: string,
        R_s_nom_atr: string,
    };
}

export const AddEditInfo = (productInfo:any) => {
    
    const {state} = useLocation();
    const [option1, setoption1] = React.useState(false);
    const [Stock, setStock] = React.useState([] as any);
    const [isEditable, setIsEditable] = React.useState(true);
    const [atributtes, setAtributtes] = React.useState([] as any);
    const [infoCategory,setInfoCategory] = React.useState([] as any);
    const [ValorUnitario, setValorUnitario] = React.useState([] as any);
    const [ValorComercial, setValorComercial] = React.useState([] as any);
    const [NombreProducto, setNombreProducto] = React.useState([] as any);
    const [PruebaVector, setPruebaVector] = React.useState([] as any);
    
    useEffect(()=>{
        allAttribute(); 
        setInfoCategory(state);
    },[]);

    const handleSubmit =async () => {
        var atr_tip_product = "";
        if(NombreProducto !== "" && ValorUnitario !== "" && ValorComercial !== "" && Stock !== "" && NombreProducto.length !== 0 && ValorUnitario.length !== 0 && ValorComercial.length !== 0 && Stock.length !== 0){
            for(var i = 0; i < PruebaVector.length; i++){
                if(PruebaVector[i][0] !== null && PruebaVector[i][2] !== null)
                    atr_tip_product = atr_tip_product + PruebaVector[i][0] + "," + PruebaVector[i][2] + ";";
            }
            
            if (productInfo.productInfo.R_s_cod_prod != null){
                const updateProduct= await InventoryService.postProductUpdate({
                    pws_cod_prod: productInfo.productInfo.R_s_cod_prod,
                    pws_c_descrip:NombreProducto,
                    pws_precio_uni:ValorUnitario,
                    pws_precio_comer:ValorComercial,
                    pws_stock:Stock,
                    pws_imagen:productInfo.productInfo.R_s_link_img,
                    pws_cod_cate:infoCategory.R_s_cod_cate,
                    pws_tipo_prod:"1",
                    pws_atr_tip_productos:atr_tip_product
                });
                toast.success(updateProduct.payload.message)
            }else{
                const insertProduct= await InventoryService.postProductInsert({
                    pws_c_descrip:NombreProducto,
                    pws_precio_uni:ValorUnitario,
                    pws_precio_comer:ValorComercial,
                    pws_stock:Stock,
                    pws_imagen:"http://cygnus.cabarcassarmiento.com:8084/catalogos/img/APACHERTR2004VXC/APACHERTR2004VXC.png",
                    pws_cod_cate:infoCategory.R_s_cod_cate,
                    pws_tipo_prod:"1",
                    pws_atr_tip_productos:atr_tip_product
                });
                toast.success(insertProduct.payload.message)
                
                setStock('');
                setValorUnitario('');
                setNombreProducto('');
                setValorComercial('');
                for(let k = 0; k < PruebaVector.length; k++){
                    PruebaVector[k][2] = "";
                }
            }
                
        }else{
            console.log("Ingresa")
            if(NombreProducto === "" || NombreProducto.length === 0)
                toast.error("Agregar Nombre del Producto")
            if(ValorUnitario === "" || ValorUnitario.length === 0)
                toast.error("Agregar Valor Unitario de la Moto")
            if(ValorComercial === "" || ValorComercial.length === 0)
                toast.error("Agregar Valor Comercial de la Moto")
            if(Stock === "" || Stock.length === 0)
                toast.error("Agregar Stock")

        }
    }

    const allAttribute= async ()=>{
        const valorProduct= await InventoryService.postAttributesQuery({
            pws_cod_atr:"",
        });
        if(valorProduct.payload.data.RespDetAtr != null){
            const ResponseService = valorProduct.payload.data.RespDetAtr.RespuestaDetAtri;
            const miMatriz = new Array(ResponseService.length).fill(null).map(() => new Array(3).fill(null));
            for(var i = 0; i < ResponseService.length; i++){
                miMatriz[i][0] = ResponseService[i].R_s_cod_atr;
                miMatriz[i][1] = ResponseService[i].R_s_nom_atr;            
            }
            setPruebaVector(miMatriz)
            setAtributtes(ResponseService)
        }        
    }

    function handleFileInputChange() {
        let nombre = 'Pagare'
        fetch('http://20.110.62.105/inventario/deportivas/hola'+'.png').then(response => {
            // manejar la respuesta del servidor
            // convierte la respuesta en un objeto blob y crea un enlace para descargar
            response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = nombre+'.png';
            document.body.appendChild(a);
            a.click();
            a.remove();
            });
        });       
    }

    
    const Item : React.FC <ItemProp> = ({item}) => {
        const handleFileInputChangePrueba = (e:any) =>{
            for(var i = 0; i < PruebaVector.length; i++){
                const Num = i+1;
                if(item.R_s_cod_atr === Num.toString()){
                    PruebaVector[i][2] = e.target.value;
                }   
            }
        }
        return (
            <ul>
                <li className={'Numeracion'}>{item.R_s_cod_atr}</li>
                <li className={'TipoAtributo'}>{item.R_s_nom_atr}</li>
                <input className={'Descripcion'} type={'text'} onChange={handleFileInputChangePrueba} />
            </ul>
        );
    }
    
    return (
        <div className="editInfo">
            <Formik 
                initialValues={{
                        pws_c_descrip:productInfo.productInfo.R_s_nom_prod,
                        pws_precio_uni:productInfo.productInfo.R_s_precio_uni,
                        pws_precio_comer:productInfo.productInfo.R_s_precio_comer,
                        pws_stock:productInfo.productInfo.R_s_unid_stock,
                        pws_imagen:productInfo.productInfo.R_s_link_img,
                }}
                onSubmit={handleSubmit}>

                {({errors, isSubmitting}) =>{                    
                    return (
                    <>
                    <Form>
                        <div className="image">
                            <img src="img/modelo-1.png" alt="Moto" />
                            <div className="subtitle">Foto del producto</div>
                            <div className={option1?"document check":"document"} onClick={(eve) => {eve.currentTarget.querySelector('input')?.click()}}>
                                <input data-id="1" type="file" name="file-input" id="file-input" className="file-input__input" accept="application/pdf, image/png, image/jpg, image/jpeg" onChange={handleFileInputChange}/>
                                <button>Cambiar foto</button>
                            </div>
                        </div>
                        <div className="info">
                            <ul>
                                <li className="LiStyle">
                                    <label>Nombre del producto</label>
                                    <input type={'text'} className="InDataEntrada" value={NombreProducto} onChange={e => setNombreProducto(e.target.value)}/>
                                </li>
                                <li className="LiStyle">
                                    <label>Stock</label>
                                    <input type={'text'} className="InDataEntrada" value={Stock} onChange={e => setStock(e.target.value)}/>
                                </li>
                            </ul>
                            <ul>
                                <li className="LiStyle">
                                    <label>Valor comercial de la moto</label>
                                    <input type={'text'} className="InDataEntrada" value={ValorComercial} onChange={e => setValorComercial(e.target.value)} />
                                </li>
                                <li className="LiStyle">
                                    <label>Valor unitario de la moto</label>
                                    <input type={'text'}className="InDataEntrada" value={ValorUnitario} onChange={e => setValorUnitario(e.target.value)} />
                                </li>
                            </ul>
                            <div className="attributos">
                                <p>Atributos</p>
                                <div className="table">
                                    <div className="edit">
                                        <span style={{cursor: 'pointer'}}><Edit /></span>
                                    </div>
                                    <div className="table-body">
                                        <div className="body">
                                        {
                                            atributtes && atributtes.map((item:any, i:any)=>{
                                                return (
                                                    <Item item={item} key={i} />
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="submit">
                                    <button style={{background: '#000000', color: '#fff', padding: '5px 10px', borderRadius: '9px'} } >Guardar</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                    </>
                    )
                }                 
            }
            </Formik>
        </div>
    )
}
