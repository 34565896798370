import React, { useState } from 'react';
import './ddcr.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import {DetailCreditRequire} from '../../components/shared/DetailCreditRequire/DetailCreditRequire';
import Direccion from '../../components/shared/modal/Direccion';
export const DetalleDelCodeudorRequerido = () => {
  const [popup, setPopup] = useState("close");
  return (
    <>
    {
            popup === "open"
            ?<>
                <Direccion setPopup={setPopup}/>
            </>
            :<></>
         }
      <ResponsiveAppBar />
      <DetailCreditRequire setPopup={setPopup}/>
    </>
  )
}
