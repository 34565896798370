import React, { SetStateAction } from 'react';
import './otpf.scss';

interface OTPProp {
  setfunc: React.Dispatch<SetStateAction<boolean>>;
}

export const OTPFunc: React.FC <OTPProp> = ({setfunc}) => {
  function clickOtp(first: string,last: string){
    const current = document.getElementById(first) as HTMLInputElement;
    if(current?.value?.length) {
      const next = document.getElementById(last) as HTMLInputElement;
      if(next){
        next.focus();
      }else{
        document.getElementById("continue")?.focus();
      }
    }
  }
  function submitCode (e: React.KeyboardEvent<HTMLButtonElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      const button = document.getElementById("continue");
      button?.addEventListener('click',()=>{
        setfunc(false);
      })
      button?.click();
    }
  }
  return (
    <div className='otpfunc'>
        <div className="setotp">
            <p>Al ingresar el código, aceptas ser consultado en listas restrictivas y sagrilaft.Por favor inserta aquí:</p>
            <div className="otpset">
                <input className='otpnum' type="text" maxLength={1} id="first" onKeyUp={(event)=>clickOtp('first','sec')} name='otp1' />
                <input className='otpnum' type="text" maxLength={1} id="sec" onKeyUp={(event)=>clickOtp('sec','thir')} name='otp1' />
                <input className='otpnum' type="text" maxLength={1} id="thir" onKeyUp={(event)=>clickOtp('thir','fourth')} name='otp1' />
                <input className='otpnum' type="text" maxLength={1} id="fourth" onKeyUp={(event)=>clickOtp('fourth','fifth')} name='otp1' />
                <input className='otpnum' type="text" maxLength={1} id="fifth" onKeyUp={(event)=>clickOtp('fifth','sixth')} name='otp1' />
                <input className='otpnum' type="text" maxLength={1} id="sixth" onKeyUp={(event)=>clickOtp('sixth','seventh')} name='otp1' />
             </div>
        </div>
        <div className="submit">
            <button onKeyUp={(event)=>submitCode(event)} onClick={()=>setfunc(false)} id="continue">Continuar</button>
        </div>
    </div>
  )
};
